import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";
import "./ToolTip.scss";

const TextToolTip = ({children, content, mobile = false}) => {

    const [openDropdown, setOpenDropdown] = useState(false);
    const buttonRef = useRef();
    const placeholderRef = useRef();

    useEffect(() => {
        document.addEventListener('click', clickOutsideHandler, false);
        return () => {
            document.removeEventListener('click', clickOutsideHandler, false);
        }
    }, []);

    useEffect(() => {
        if(openDropdown){
            if (placeholderRef.current) {
                setTimeout(() => {
                    if (document.documentElement.clientWidth < placeholderRef.current.getBoundingClientRect().right) {
                        placeholderRef.current.classList.add("position__right");
                    }
                }, 1);
            }
        }
    }, [openDropdown]);

    const clickOutsideHandler = (event) => {
        if (!buttonRef.current?.contains(event.target)) {
            setOpenDropdown(false);
        }
    }

    const handleClick = () => {
        setOpenDropdown(openDropdown => !openDropdown);
    }

    const handleMouseEnter = () => {
        setOpenDropdown(true);
    }
    const handleMouseLeave = () => {
        setOpenDropdown(false);
    }

    return (
        <div className={cn('tooltip', {'mobile': mobile})}>
            <div
                onClick={handleClick}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                ref={buttonRef}
            >
                {children}
            </div>
            <div className={cn('placeholder', 'placeholder__inline', {'active': openDropdown})} ref={placeholderRef}>
                <div className={cn('triangle')}>
                    <div className={cn('triangle-with-shadow')}/>
                </div>
                {content}
            </div>
        </div>
    )
}

/**
 *
 * @param children
 * @param {boolean} mobile
 * @returns {JSX.Element}
 * @constructor
 */
const ToolTip = ({children, mobile = false}) => {

    const [openDropdown, setOpenDropdown] = useState(false);
    const buttonRef = useRef();
    const placeholderRef = useRef();

    useEffect(() => {
        document.addEventListener('click', clickOutsideHandler, false);
        return () => {
            document.removeEventListener('click', clickOutsideHandler, false);
        }
    }, []);

    useEffect(() => {
        if(openDropdown){
            if (placeholderRef.current) {
                setTimeout(() => {
                    if (document.documentElement.clientWidth < placeholderRef.current.getBoundingClientRect().right) {
                        placeholderRef.current.classList.add("position__right");
                    }
                }, 1);
            }
        }
    }, [openDropdown]);

    const clickOutsideHandler = (event) => {
        if (!buttonRef.current?.contains(event.target)) {
            setOpenDropdown(false);
        }
    }

    const handleClick = () => {
        setOpenDropdown(openDropdown => !openDropdown);
    }

    const handleMouseEnter = () => {
        setOpenDropdown(true);
    }
    const handleMouseLeave = () => {
        setOpenDropdown(false);
    }
    return (
        <div className={cn('tooltip', {'mobile': mobile})}>
            <i
                className="pi pi-exclamation-circle"
                onClick={handleClick}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                ref={buttonRef}
            />
            <div className={cn('placeholder', {'active': openDropdown})} ref={placeholderRef}>
                <div className={cn('triangle')}>
                    <div className={cn('triangle-with-shadow')}/>
                </div>
                {children}
            </div>
        </div>
    )
}

export {
    TextToolTip,
    ToolTip
};

import cn from "classnames";
import Za from "images/dashboard/za.png";
import ico_uparrow from "images/dashboard/ico_uparrow.png";
import ico_minus from "images/dashboard/ico_minus.png";
import ico_downarrow from "images/dashboard/ico_downarrow.png";
import React, {useEffect, useRef, useState} from "react";
import {
    isMobile
} from "react-device-detect";
import {Link} from "react-router-dom";
import {inject, observer} from "mobx-react";

const SMBranchBlock = ({data}) => {

    const [adPlatform, setAdPlatform] = useState(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if(data.sources.length > 0) {
            setAdPlatform({
                decreased: data.sources[0].decreased,
                equaled: data.sources[0].equaled,
                increased: data.sources[0].increased,
                total: data.total,
                source: {code: data.sources[0].source.code, name: data.sources[0].source.name},
            });
        }
        else {
            setAdPlatform({
                decreased: data.decreased,
                equaled: data.equaled,
                increased: data.increased,
                total: data.total,
                source: {code: '', name: ''},
            });
        }

        setLoading(true);
    }, [])

    const handleChange = (result) => {
        const newAdPlatform = data.sources.find((source) => {
            return source.source.code === result.code
        });

        if (newAdPlatform) {
            setAdPlatform(
                {
                    decreased: newAdPlatform.decreased,
                    equaled: newAdPlatform.equaled,
                    increased: newAdPlatform.increased,
                    total: data.total,
                    source: {code: newAdPlatform.source.code, name: newAdPlatform.source.name},
                }
            )
        }
    }

    return (
        <>
            <Link to={'/analytics/rating'}>
                {!!data.sources?.length && <ActionButtonSingle
                    handleChange={handleChange}
                    sources={data.sources}
                />}

                {loading && <fieldset className={cn('ml_0')}>

                    <legend>Филиалы: репутация</legend>
                    <div className={cn('row', 'row__md_10')}>

                        <div className={cn('col', 'col__big')}>
                            <span>
                                <img src={Za} alt=""/>
                            </span>
                            <span className={cn('value')}>{adPlatform.total}</span>
                        </div>
                        {adPlatform.source.code && <div className={cn('col', 'col__big-text')}>
                            {adPlatform.source.name}
                        </div>}

                        {isMobile ?
                            <div className={cn('right_side')}>
                                {!data.sources.length ?
                                    <div className={cn('col')}>Нет данных</div>
                                    :
                                    <>
                                        <div className={cn('col')}>
                                            <span className={cn('ico')}>
                                                <img src={ico_uparrow} alt=""/>
                                            </span>
                                            {adPlatform.increased}
                                        </div>
                                        <div className={cn('col')}>
                                            <span className={cn('ico')}>
                                                <img src={ico_minus} alt=""/>
                                            </span>
                                            {adPlatform.equaled}
                                        </div>
                                        <div className={cn('col')}>
                                            <span className={cn('ico')}>
                                                <img src={ico_downarrow} alt=""/>
                                            </span>
                                            {adPlatform.decreased}
                                        </div>
                                    </>
                                }
                            </div>
                            :
                            <>
                                {!data.sources.length ?
                                    <div className={cn('col')}>Нет данных</div>
                                    :
                                    <>
                                        <div className={cn('col')}>
                                            <span className={cn('ico')}>
                                                <img src={ico_uparrow} alt=""/>
                                            </span>
                                            {adPlatform.increased}
                                        </div>
                                        <div className={cn('col')}>
                                            <span className={cn('ico')}>
                                                <img src={ico_minus} alt=""/>
                                            </span>
                                            {adPlatform.equaled}
                                        </div>
                                        <div className={cn('col')}>
                                            <span className={cn('ico')}>
                                                <img src={ico_downarrow} alt=""/>
                                            </span>
                                            {adPlatform.decreased}
                                        </div>
                                    </>
                                }

                            </>}
                    </div>
                </fieldset>}
            </Link>
        </>

    )
}


const ActionButtonSingle = inject('dashboardStore')(observer(
    ({handleChange, sources}) => {

        const buttonRef = useRef();

        const [selectedItem, setSelectedItem] = useState({
            code: sources[0].source.code,
            name: sources[0].source.name,
        });

        const [openDropdown, setOpenDropdown] = useState(false);

        useEffect(() => {
            document.addEventListener('click', clickOutsideHandler, false);
            return () => {
                document.removeEventListener('click', clickOutsideHandler, false);
            }
        }, []);

        useEffect(() => {
            handleChange(selectedItem);
        }, [selectedItem])


        const clickOutsideHandler = (event) => {
            if (!buttonRef.current?.contains(event.target)) {
                setOpenDropdown(false);
            }
        }

        const addItem = (item) => {
            setSelectedItem({
                    name: item.source.name,
                    code: item.source.code,
                }
            );
        }

        return (
            <div
                className={cn('double-block__filter')}
                ref={buttonRef}
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setOpenDropdown(openDropdown => !openDropdown);
                }}
            >
                {openDropdown && <ul>
                    {sources.map((item, key) => {
                        const isSelect = (selectedItem.code === item.source.code);

                        return (
                            <li key={key} onClick={(e) => {
                                !isSelect && addItem(item);
                                e.stopPropagation();
                                e.preventDefault();
                            }}
                            >
                                {isSelect && <i className="pi pi-check"/>}
                                {item.source.name}
                            </li>
                        );
                    })}
                </ul>}

            </div>

        );
    }
))

export default SMBranchBlock;

import cn from "classnames";
import React, { useEffect, useState } from "react";
import _ from "underscore";
import { useIsMount } from "../../../../hooks";
import { inject, observer } from "mobx-react";
import { RequiresAttentionButton, FilterButton } from '../../../common/FilterButton';
import OkDateRangePicker from '../../../common/OkDateRangePicker';

import './Filter.scss';

const Filter = ({ reviewFilterStore, tagStore, loading, shortFormat }) => {

    const { branches, statuses, scores, sources, sentiments, claims, publicationsStatuses, filter, setFieldValue } = reviewFilterStore;
    const {tagsListForFilter, tagsListAutoForFilter } = tagStore;
    const [disabledFilter, setDisabledFilter] = useState(!!filter.attention[0].value);
    const [attentionNum, setAttentionNum] = useState(null);
    const isMount = useIsMount();

    useEffect(() => {
        tagStore.getTagsForFilter()
        reviewFilterStore.getBranches();
        reviewFilterStore.getSources();
    }, [])

    useEffect(() => {
        reviewFilterStore.loadAttentionNum().then(result => {
            setAttentionNum(result);
        });
    }, [JSON.stringify(filter)])

    const setValue = _.debounce((field, data) => {
        if (!isMount) {
            setFieldValue(field, data);
        }
    }, 800);

    const onChangeRangeDate = (date) => {
        reviewFilterStore.setDate({
            from: date.startDate,
            to: date.endDate,
        });
    }

    const clear = () => {
        reviewFilterStore.clear();
        setDisabledFilter(false);
    }

    const setOrientation = () => {
        return shortFormat=== true ? 'vertical' : 'horizontal';
    }

    return (
        <>
            <div className={cn('filter__header')}>
                <div className={'filter__title'}>
                    <h3>Фильтр</h3>
                </div>
                <div
                    className={'filter__clear-btn'}
                    onClick={clear}
                >Очистить все
                </div>
            </div>
            <div className={cn('filter__wrapper')}>
                <div className={'filter__item'}>
                    <OkDateRangePicker
                        onChangeDateHandler={onChangeRangeDate}
                        dateRange={filter.date}
                        orientation = {setOrientation()}
                        disabled={disabledFilter}
                    />
                </div>
                {branches && <div className={'filter__item'}>
                    <FilterButton
                        name='Филиал'
                        list={branches}
                        onChange={(data) => setValue('branches', data)}
                        onClear={() => setFieldValue('branches', [])}
                        filter={filter.branches}
                        disabled={disabledFilter}
                        loading={loading}
                        search={true}
                    />
                </div>}
                {sources &&<div className={'filter__item'}>
                    <FilterButton
                        name='Источник'
                        list={sources}
                        onChange={(data) => setValue('sources', data)}
                        onClear={() => setFieldValue('sources', [])}
                        filter={filter.sources}
                        disabled={disabledFilter}
                        loading={loading}
                    />
                </div>}
                <div className={'filter__item'}>
                    <FilterButton
                        name='Оценка'
                        list={scores}
                        onChange={(data) => setValue('scores', data)}
                        onClear={() => setFieldValue('scores', [])}
                        filter={filter.scores}
                        disabled={disabledFilter}
                        loading={loading}
                    />
                </div>
                <div className={'filter__item'}>
                    <FilterButton
                        name='Тональность'
                        list={sentiments}
                        onChange={(data) => setValue('sentiments', data)}
                        onClear={() => setFieldValue('sentiments', [])}
                        filter={filter.sentiments}
                        disabled={disabledFilter}
                        loading={loading}
                    />
                </div>

                <div className={'filter__item'}>
                    <FilterButton
                        name='Статус ответа'
                        list={statuses}
                        onChange={(data) => setValue('answer', data)}
                        onClear={() => setFieldValue('answer', [])}
                        filter={filter.answer}
                        disabled={disabledFilter}
                        loading={loading}
                    />
                </div>
                <div className={'filter__item'}>
                    <FilterButton
                        name='Статус публикации'
                        list={publicationsStatuses}
                        onChange={(data) => setValue('publication', data)}
                        onClear={() => setFieldValue('publication', [])}
                        filter={filter.publication}
                        disabled={disabledFilter}
                        loading={loading}
                    />
                </div>

                <div className={'filter__item'}>
                    <FilterButton
                        name='Статус жалобы'
                        list={claims}
                        onChange={(data) => setValue('claim', data)}
                        onClear={() => setFieldValue('claim', [])}
                        filter={filter.claim}
                        disabled={disabledFilter}
                        loading={loading}
                    />
                </div>
                {tagsListForFilter && <div className={'filter__item'}>
                    <FilterButton
                        name='Теги'
                        list={tagsListForFilter}
                        onChange={(data) => setValue('tags', data)}
                        onClear={() => setFieldValue('tags', [])}
                        filter={filter.tags}
                        disabled={disabledFilter || !tagsListForFilter.length}
                        loading={loading}
                    />
                </div>}

                {tagsListAutoForFilter && <div className={'filter__item'}>
                    <FilterButton
                        name='Автотеги'
                        list={tagsListAutoForFilter}
                        onChange={(data) => setValue('autotags', data)}
                        onClear={() => setFieldValue('autotags', [])}
                        filter={filter.autotags}
                        disabled={disabledFilter || !tagsListAutoForFilter.length}
                        loading={loading}
                    />
                </div>}

                <div className={cn('filter__item')}
                >
                    <RequiresAttentionButton
                        name='Требует внимания'
                        active={disabledFilter}
                        handlerClick={() => {
                            setDisabledFilter(prev => !prev);
                            setValue('attention', [{ value: +(!disabledFilter) }]);
                        }}
                        selectedItems={attentionNum?.attention}
                    />
                </div>
            </div>
        </>
    )
}


export default inject('reviewFilterStore', 'tagStore')(observer(Filter));

import React, { useEffect, useState } from "react";
import cn from "classnames";
import { inject, observer } from "mobx-react";
import { Dropdown } from "primereact/dropdown";
import { FilterApsBranch } from "../../../common/Filter";
import { getDetailDataByPlatform } from "../../../../helpers";
import Tabs from "../Tabs";
import SlideNav from "../SlideNav";
import PrepareNumber from "../../../../components/common/PrepareNumber";
import './ReviewsList.scss';


const BranchMobile = ({ analyticsStore }) => {

    const { filterBranch } = analyticsStore;
    const [reviews, setReviews] = useState({ items: [] }); //отзывы
    const [appls, setAppls] = useState({ items: [] }); // заявки
    const [loading, setLoading] = useState(true);

    const [blockTable, setBlockTable] = useState('review'); //review | appl

    useEffect(() => {
        setLoading(true);
        Promise.all([
            analyticsStore.getReviewsByPlatformList(),
            analyticsStore.getApsManagerByPlatformList(),
        ]).then(([reviews, appls]) => {
            setReviews(reviews);
            setAppls(appls);
            setLoading(false);
        });
    }, [JSON.stringify(filterBranch)]);


    return (
        <>
            <div className={cn('page__analytics-mobile', 'page')}>
                <div className={cn('title')}>
                    <h1>Аналитика</h1>
                </div>
                <div className={cn('analytics-tabs')}>
                    <Tabs />
                </div>
                <div className={cn('analytics-filter')}>
                    <FilterApsBranch
                        loading={loading}
                        mobile={true}
                    />
                    <div className={'filter__wrapper'}>
                        <div className={'filter__item'}>
                            <SlideNav
                                handleChangeNav={(ref) => setBlockTable(ref)}
                                data={[
                                    { 'review': 'Отзывы' },
                                    { 'appl': 'Заявки' }
                                ]}
                                value={'review'}
                            />
                        </div>
                    </div>
                </div>

                {blockTable === 'review' && <ReviewsList
                    data={reviews}
                />}
                {blockTable === 'appl' && <ApplList
                    data={appls}
                />}

            </div>
        </>
    );
}

const ReviewsList = ({ data }) => {

    const partListArr = [
        { "value": 'rating', "label": 'Рейтинг' },
        { "value": 'scores', "label": 'Оценки' },
        { "value": 'reviews', "label": 'Отзывы' },
        { "value": 'responded', "label": 'С ответом' },
    ];

    let templateSelect = {
        claimed: [],
        claims: [],
        rating: [],
        scores: [],
        reviews: [],
        responded: [],
        answers: [],
        replies: [],
        timestamp: [],
    };

    let templateSelectTotals = {
        claimed: [],
        claims: [],
        rating: [],
        scores: [],
        reviews: [],
        responded: [],
        answers: [],
        replies: [],
        timestamp: [],
    };

    const [select, setSelect] = useState([]);
    const [part, setPart] = useState(null);
    const [items, setItems] = useState({});
    const [totals, setTotals] = useState({});

    useEffect(() => {
        for (const key in data.total?.close) {
            templateSelectTotals[key].push({
                name: 'Все источники',
//если блок "с ответом (responded)", то его значение item.close.responded (ответов на отзыв) / item.close.reviews (всего отзывов)*100 = процент ответов на отзыв.
                value: key !== 'responded'
                    ?
                    data.total.close[key]
                    :
                    data.total.close['reviews'] ? data.total.close[key]/data.total.close['reviews']*100 : 0,
                diff: data.total.diff[key],
            })
        }
        setTotals(templateSelectTotals);

        data.items.length > 0 && data.items.map(item => {
            for (const key in item.close) {
                templateSelect[key].push({
                    name: item.adplatform.code,
                    url: item.adplatform.url,
//если блок "с ответом (responded)",
// то его значение item.close.responded (ответов на отзыв) / item.close.reviews (всего отзывов)*100 = процент ответов на отзыв.
                    value: key !== 'responded'
                        ?
                        item.close[key]
                        :
                        item.close['reviews'] ? item.close[key]/item.close['reviews']*100 : 0,
                    diff: item.diff[key],
                })
            }
        });

        setSelect(partListArr)
        setItems(templateSelect);
    }, [data]);

    useEffect(() => {
        setPart(select[0]?.value);
    }, [select]);

    return (
        <>
            {select.length && <div className={cn('analytics-filter')}>
                <Dropdown inputId="select"
                          name="select"
                          options={select}
                          value={part}
                          style={{ 'width': '100%' }}
                          onChange={(e) => setPart(e.target.value)}
                          optionLabel="label"
                />
            </div>}
            <div className={cn('analytics-list')}>
                <div className={cn('analytics-list__table')}>
                    <div className={cn('analytics-list__wrapper')}>
                        <div className={cn('analytics-list__item', 'item', 'branch-mobile')}>
                            <div className={cn('item__wrapper')}>
                                {part && totals[part].length > 0 && totals[part].map((item, key) => (
                                    <div className={cn('row')} key={key}>
                                        <div className={cn('col', 'align__bottom')}>{item.name}</div>
                                        {part === 'responded' ?
                                            <div className={cn('col', 'align__right')}>{item.value.toFixed()}%</div>
                                            :
                                            <>
                                                <div className={cn('col', 'align__left')}>
                                                    {part === 'rating' ?
                                                        <span className={cn('rating')}>
                                                            <span className={cn('pi-star pi')} />
                                                            <span className={cn('value')}>
                                                                {Number(`${item.value}`).toLocaleString('ru-RU')}
                                                            </span>
                                                        </span>
                                                        :
                                                        Number(`${item.value}`).toLocaleString('ru-RU')
                                                    }
                                                </div>
                                                <div className={cn(
                                                    'col',
                                                    'diff',
                                                    { 'diff__up': Number(item.diff) >= 0 },
                                                    { 'diff__down': Number(item.diff) < 0 }
                                                )}
                                                >
                                                    <PrepareNumber number={item.diff} />
                                                </div>

                                            </>
                                        }
                                    </div>
                                ))}
                                {part && items[part].length > 0 && items[part].map((item, key) => {
                                    const { name, ico } = getDetailDataByPlatform(item.name)
                                    return (<div className={cn('row')} key={key}>
                                        <div className={cn('col', 'align__bottom')}>
                                            <a href={item.url} target="_blank">
                                                <img src={ico} alt="" width={20} height={20} />
                                            </a>
                                            <div>{name}</div>
                                        </div>
                                        {part === 'responded' ?
                                            <div className={cn('col', 'align__right')}>{item.value.toFixed()}%</div>
                                            :
                                            <>
                                                <div className={cn('col', 'align__left')}>
                                                    {part === 'rating' ?
                                                        <span className={cn('rating')}>
                                                            <span className={cn('pi-star pi')} />
                                                            <span className={cn('value')}>
                                                                {Number(`${item.value}`).toLocaleString('ru-RU')}
                                                            </span>
                                                        </span>
                                                        :
                                                        Number(`${item.value}`).toLocaleString('ru-RU')
                                                    }
                                                </div>
                                                <div className={cn(
                                                    'col',
                                                    'diff',
                                                    { 'diff__up': Number(item.diff) >= 0 },
                                                    { 'diff__down': Number(item.diff) < 0 }
                                                )}
                                                >
                                                    <PrepareNumber number={item.diff} />
                                                </div>
                                            </>
                                        }
                                    </div>)
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

const ApplList = ({ data }) => {

    const partListArr = [
        { "value": 'applications_total', "label": 'Всего заявок за период ' },
        { "value": 'applications_progress', "label": 'Заявки в работе' },
        { "value": 'applications_error', "label": 'Заявки с ошибкой' },
        { "value": 'reviews_total', "label": 'Всего получено отзывов' },
        { "value": 'rates_conversion', "label": 'Конверсия' },
        { "value": 'reviews_good', "label": 'Получено поз. отзывов' },
        { "value": 'reviews_bad', "label": 'Перехвачено нег. отзывов' },
        { "value": 'reviews_approved', "label": 'Подтв. отзывы на доп. площадках' },
        { "value": 'rates_nps', "label": 'NPS' },
    ];

    let templateSelect = {
        applications_total: [],
        applications_progress: [],
        applications_error: [],
        reviews_total: [],
        rates_conversion: [],
        reviews_good: [],
        reviews_bad: [],
        reviews_approved: [],
        rates_nps: [],
    };
    let templateSelectTotals = {
        applications_total: [],
        applications_progress: [],
        applications_error: [],
        reviews_total: [],
        rates_conversion: [],
        reviews_good: [],
        reviews_bad: [],
        reviews_approved: [],
        rates_nps: [],
    };

    const [select, setSelect] = useState([]);
    const [part, setPart] = useState(null);
    const [items, setItems] = useState({});
    const [totals, setTotals] = useState({});

    useEffect(() => {
        setPart(select[0]?.value);
    }, [select]);

    useEffect(() => {

//TODO !!!оптимизировать -)
        templateSelectTotals['applications_total'].push({
            name: 'Все сотрудники',
            value: data.total.applications.total,
        });
        templateSelectTotals['applications_progress'].push({
            name: 'Все сотрудники',
            value: data.total.applications.progress,
        });
        templateSelectTotals['applications_error'].push({
            name: 'Все сотрудники',
            value: data.total.applications.error,
        });
        templateSelectTotals['reviews_total'].push({
            name: 'Все сотрудники',
            value: data.total.reviews.total,
        });
        templateSelectTotals['rates_conversion'].push({
            name: 'Все сотрудники',
            value: data.total.rates.conversion,
        });
        templateSelectTotals['reviews_bad'].push({
            name: 'Все сотрудники',
            value: data.total.reviews.bad,
        });
        templateSelectTotals['reviews_approved'].push({
            name: 'Все сотрудники',
            value: data.total.extreviews.other ?? 0,
        });
        templateSelectTotals['reviews_good'].push({
            name: 'Все сотрудники',
            value: data.total.reviews.good,
        });

        templateSelectTotals['rates_nps'].push({
            name: 'Все сотрудники',
            value: data.total.rates.nps,
        });
        setTotals(templateSelectTotals);

//TODO !!!оптимизировать -)
        data.items.length > 0 && data.items.map(item => {
            templateSelect['applications_total'].push({
                name: item.manager.name,
                value: item.applications.total,
            });

            templateSelect['applications_progress'].push({
                name: item.manager.name,
                value: item.applications.progress,
            });

            templateSelect['applications_error'].push({
                name: item.manager.name,
                value: item.applications.error,
            });

            templateSelect['reviews_total'].push({
                name: item.manager.name,
                value: item.reviews.total,
            });

            templateSelect['rates_conversion'].push({
                name: item.manager.name,
                value: item.rates.conversion,
            });

            templateSelect['reviews_bad'].push({
                name: item.manager.name,
                value: item.reviews.bad,
            });

            templateSelect['reviews_good'].push({
                name: item.manager.name,
                value: item.reviews.good,
            });
            templateSelect['reviews_approved'].push({
                name: item.manager.name,
                value: item.extreviews.other ?? 0,
            });

            templateSelect['rates_nps'].push({
                name: item.manager.name,
                value: item.rates.nps,
            });

        });
        setSelect(partListArr)
        setItems(templateSelect);

    }, [data]);

    return (
        <>
            {select.length && <div className={cn('analytics-filter')}>
                <Dropdown inputId="select"
                          name="select"
                          options={select}
                          value={part}
                          style={{ 'width': '100%' }}
                          onChange={(e) => setPart(e.target.value)}
                          optionLabel="label"
                />
            </div>}
            <div className={cn('analytics-list')}>
                <div className={cn('analytics-list__table')}>
                    <div className={cn('analytics-list__wrapper')}>
                        <div className={cn('analytics-list__item', 'item', 'appl-mobile')}>
                            <div className={cn('item__wrapper')}>
                                {part && totals[part].length > 0 && totals[part].map((item, key) => (

                                    <div className={cn('row')} key={key}>
                                        <div className={cn('col', 'align__bottom')}>
                                            <div>{item.name}</div>
                                        </div>
                                        <div className={cn('col', 'align__right')}>{item.value.toFixed()}</div>
                                    </div>
                                ))}

                                {part && items[part].length > 0 && items[part].map((item, key) => (
                                    <div className={cn('row', 'name')} key={key}>
                                        <div className={cn('col', 'align__bottom')}>
                                            <div className="ico ico__user"/>
                                            <div>{item.name}</div>
                                        </div>
                                          <div className={cn('col', 'align__right')}>{item.value.toFixed()}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default inject('analyticsStore')(observer(BranchMobile));

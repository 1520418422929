import React, { useState } from "react";
import cn from "classnames";
import GeoTabs from "../GeoTab";

import stop from "../../../../images/icons/stop.svg";
import error from "../../../../images/icons/error.svg";
import check from "../../../../images/icons/check.svg";
import google from "../../../../images/platforms_icon_final/08.png";
import yandex from "../../../../images/platforms_icon_final/01.png";
import toGis from "../../../../images/platforms_icon_final/02.png";

import { processing_icon, okreview_icon } from "../../../../helpers/icons";


import { Sidebar } from "primereact/sidebar";
import { pluralize } from "../../../../helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./CompanyStatistics.scss";


const CompanyStatistics = () => {

    const [visibleCompareSideBar, setVisibleCompareSideBar] = useState(false);
    const [activeBlock, setActiveBlock]  = useState('yandex')

    function getActiveBlock(name = 'yandex') {
        return name === activeBlock;
    }

    return (
        <>
            <div className={cn('page__company-list', 'page')}>
                <div className={'company-list'}>
                    <div className={cn('title')}>
                        <h1>Геоданные</h1>
                    </div>
                    <div className={cn('company-tabs')}>
                        <GeoTabs/>
                    </div>
                    <div className={cn('company-statistics')}>
                        <div className={cn('statistics', 'statistics__wrapper')}>

                            <div className={cn('block')}>
                                <div className={cn('block__title')}>
                                    <h3>Обновление геоданных</h3>
                                </div>
                                <div className={cn('place-statistic')}>
                                    <div className={cn('place-statistic__header')}>
                                        <ul>
                                            <li className={cn({'active': getActiveBlock('yandex') })}
                                                onClick={() => {
                                                    setActiveBlock('yandex')
                                                }}
                                            ><img src={yandex} alt="Яндекс" width={'18px'} height={'18px'}/>Яндекс.Карты</li>
                                            <li className={cn({'active': getActiveBlock('gis') })}
                                                onClick={() => {
                                                    setActiveBlock('gis')
                                                }}
                                            ><img src={toGis} alt="2ГИС" width={'18px'} height={'18px'}/>2ГИС</li>
                                            <li className={cn({'active': getActiveBlock('google') })}
                                                onClick={() => {
                                                    setActiveBlock('google')
                                                }}
                                            ><img src={google} alt="Google" width={'18px'} height={'18px'}/>Google Maps</li>
                                        </ul>
                                    </div>
                                    <div className={cn('place-statistic__content')}>
                                        <div className={cn('content__block', {'active': getActiveBlock('yandex') })}>
                                            <div className={cn('content-top')}>
                                                <div className={cn('item')}>
                                                    <h4>Информация актуальна</h4>
                                                    <div>
                                                        <span>
                                                            <img src={check}  width={'25px'} height={'25px'}/>
                                                        </span>
                                                        <span>10 (95%)</span>
                                                    </div>
                                                </div>
                                                <div className={cn('item')}>
                                                    <h4>Имеются расхождения</h4>
                                                    <div>
                                                        <span>
                                                            <img src={error} width={'25px'} height={'25px'}/>
                                                        </span>
                                                        <span>12 (95%)</span>
                                                    </div>
                                                </div>
                                                <div className={cn('item')}>
                                                    <h4>Требуется авторизация</h4>
                                                    <div>
                                                        <span><img src={stop} width={'20px'} height={'20px'}/></span>
                                                        <span>10 (5%)</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={cn('content-bottom')}>
                                                <div className={cn('title')}>Расхождения в файлах</div>
                                                <div className={cn('items-container')}>

                                                    <div className={cn('item')}>
                                                        <h5>Название</h5>
                                                        <span>Расхождений нет</span>
                                                    </div>
                                                    <div className={cn('item')}>
                                                        <h5>Короткое название</h5>
                                                        <span>Расхождений нет</span>
                                                    </div>
                                                    <div className={cn('item')}>
                                                        <h5>Адрес</h5>
                                                        <span className={cn('error')}
                                                              onClick={() => setVisibleCompareSideBar(true)}
                                                        >5 расхождений <span className="pi pi-arrow-right"></span>
                                                        </span>
                                                    </div>
                                                    <div className={cn('item')}>
                                                        <h5>Категории</h5>
                                                        <span>Расхождений нет</span>
                                                    </div>
                                                    <div className={cn('item')}>
                                                        <h5>Телефоны, мессенджеры, эл.почта</h5>
                                                        <span>Расхождений нет</span>
                                                    </div>
                                                    <div className={cn('item')}>
                                                        <h5>Сайт и соцсети</h5>
                                                        <span>Расхождений нет</span>
                                                    </div>
                                                    <div className={cn('item')}>
                                                        <h5>График работы</h5>
                                                        <span className={cn('error')}>1 расхождение <span className="pi pi-arrow-right"></span>
                                                        </span>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className={cn('content__block', {'active': getActiveBlock('gis') })}>
                                            <div className={cn('content-top')}>
                                                <div className={cn('item')}>
                                                    <h4>Информация актуальна</h4>
                                                    <div>
                                                        <span>
                                                            <img src={check} width={'25px'}
                                                                 height={'25px'}/>
                                                        </span>
                                                        <span>10 (100%)</span>
                                                    </div>
                                                </div>
                                                <div className={cn('item')}>
                                                    <h4>Имеются расхождения</h4>
                                                    <div>
                                                        <span>
                                                            <img src={error} width={'25px'}
                                                                 height={'25px'}/>
                                                        </span>
                                                        <span>0 (0%)</span>
                                                    </div>
                                                </div>
                                                <div className={cn('item')}>
                                                    <h4>Требуется авторизация</h4>
                                                    <div>
                                                        <span><img src={stop} width={'20px'}
                                                                   height={'20px'}/></span>
                                                        <span>0 (0%)</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={cn('content-bottom')}>
{/*                                                <div className={cn('title')}>Ошибки в файлах</div>*/}
                                                <div className={cn('items-container')}>
                                                    <div className={cn('item', 'no-errors')}>
                                                        <h5>Расхождений в данных нет.</h5>
                                                        <span>Ваши данные защищаются системой OK REVIEW
                                                            <img src={check} width={'25px'} height={'25px'}/>
                                                        </span>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className={cn('content__block', {'active': getActiveBlock('google') })}>
                                            <div className={cn('content-top')}>
                                                <div className={cn('item')}>
                                                    <h4>Информация актуальна</h4>
                                                    <div>
                                                        <span>
                                                            <img src={check} width={'25px'}
                                                                 height={'25px'}/>
                                                        </span>
                                                        <span>10 (95%)</span>
                                                    </div>
                                                </div>
                                                <div className={cn('item')}>
                                                    <h4>Имеются расхождения</h4>
                                                    <div>
                                                        <span>
                                                            <img src={error} width={'25px'}
                                                                 height={'25px'}/>
                                                        </span>
                                                        <span>12 (95%)</span>
                                                    </div>
                                                </div>
                                                <div className={cn('item')}>
                                                    <h4>Требуется авторизация</h4>
                                                    <div>
                                                        <span><img src={stop} width={'20px'}
                                                                   height={'20px'}/></span>
                                                        <span>10 (5%)</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={cn('content-bottom')}>
                                                <div className={cn('title')}>Расхождения в данных филиалов</div>
                                                <div className={cn('items-container')}>

                                                    <div className={cn('item')}>
                                                        <h5>Название</h5>
                                                        <span>Расхождений нет</span>
                                                    </div>
                                                    <div className={cn('item')}>
                                                        <h5>Короткое название</h5>
                                                        <span>Расхождений нет</span>
                                                    </div>
                                                    <div className={cn('item')}>
                                                        <h5>Адрес</h5>
                                                        <span className={cn('error')}
                                                              onClick={() => setVisibleCompareSideBar(true)}
                                                        >5 расхождений <span className="pi pi-arrow-right"></span>
                                                        </span>
                                                    </div>
                                                    <div className={cn('item')}>
                                                        <h5>Категории</h5>
                                                        <span>Расхождений нет</span>
                                                    </div>
                                                    <div className={cn('item')}>
                                                        <h5>Телефоны, мессенджеры, эл.почта</h5>
                                                        <span>Расхождений нет</span>
                                                    </div>
                                                    <div className={cn('item')}>
                                                        <h5>Сайт и соцсети</h5>
                                                        <span>Расхождений нет</span>
                                                    </div>
                                                    <div className={cn('item')}>
                                                        <h5>График работы</h5>
                                                        <span className={cn('error')}>1 ошибка<br/><span
                                                            className="pi pi-arrow-right"></span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <CompareSideBar
                visibleCompareSideBar={visibleCompareSideBar}
                setVisibleCompareSideBar={setVisibleCompareSideBar}
            />

        </>
    )
}


const CompareSideBar = ({ visibleCompareSideBar, setVisibleCompareSideBar }) => {

    const [numbers, setNumbers] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);

    return (
        <Sidebar
            visible={visibleCompareSideBar}
            position="bottom"
            onHide={() => setVisibleCompareSideBar(false)}
            className={cn('side-bar', 'compare-side-bar')}
            blockScroll={true}
            icons={() => (
                <button
                    className={cn('p-link', 'close-text')}
                    onClick={() => setVisibleCompareSideBar(false)}
                >
                    Закрыть
                </button>
            )}
        >
            <div className={cn('compare-side-bar__wrapper')}>
                <div className={cn('compare-side-bar__header')}>
                    <div className={cn('compare-side-bar__title')}>
                        <b>Телефон, мессенджер</b>
                    </div>
                    <div className={cn('compare-side-bar__subtitle')}>
                        <div className={cn('compare-side-bar__subtitle-text')}>
                            5 {pluralize(5, ['расхождение', 'расхождения', 'расхождений'], false)}
                        </div>
                        <div className={cn('compare-side-bar__subtitle-date')}>
                            <img src={processing_icon} width={20} height={15} alt=""/>
                            20:00, 03 июля 2024
                        </div>
                    </div>
                </div>
                <div className={cn('table', 'data-compare-table', 'company-data-compare-table')}>
                    <div className={cn('table__wrapper')}>
                        <div className={cn('table')}>
                            <div className={cn('table__header')}>
                                <div className={cn('table__row')} style={{ "--my-repeat": 8 }}>
                                    <div className={cn('table__cell')}>Источник</div>
                                    <div className={cn('table__cell')}>Название</div>
                                    <div className={cn('table__cell')}>Короткое название</div>
                                    <div className={cn('table__cell')}>Адрес</div>
                                    <div className={cn('table__cell')}>Категории</div>
                                    <div className={cn('table__cell')}>Телефоны, мессенджеры, эл.почта</div>
                                    <div className={cn('table__cell')}>Сайт и соцсети</div>
                                    <div className={cn('table__cell')}>График работы</div>
                                </div>
                            </div>
                            {numbers.map((i, key) => (
                                <div className={cn('table__body')} key={key}>
                                    <div className={cn('table__row')} style={{ "--my-repeat": 9 }}>
                                        <div className={cn('table__cell', 'cell')}>
                                            <img src={yandex} alt="" width={14} height={14}
                                                 title="Яндекс"/>Яндекс.Карты
                                        </div>
                                        <div className={cn('table__cell', 'cell')}>
                                            Аванта-Запад
                                        </div>
                                        <div className={cn('table__cell', 'cell')}>
                                            Аванта-Запад
                                        </div>

                                        <div className={cn('table__cell', 'cell')}>
                                            Московская область,<br/>
                                            Москва, пр-т<br/>
                                            Ленина, 26
                                        </div>
                                        <div className={cn('table__cell', 'cell')}>
                                            1841000031 Магазин розничной торговли
                                        </div>
                                        <div className={cn('table__cell', 'cell')}>
                                            <ul className={cn('phone-list')}>
                                                <li className={cn(`color__black`)}>
                                                <span className={cn('phone-list__icon-wrapper')}>
                                                    <FontAwesomeIcon icon={'mobile-screen'} fixedWidth/></span>+7 000 000-00-00 (горячая линия и заказ доставки)
                                                </li>
                                                <li className={cn(`color__black`)}>
                                                <span className={cn('phone-list__icon-wrapper')}>
                                                    <FontAwesomeIcon icon={'mobile-screen'} fixedWidth/></span>+7 000 000-00-00
                                                </li>
                                            </ul>
                                        </div>
                                        <div className={cn('table__cell', 'cell')}>
                                            <ul className={cn('phone-list')}>
                                                <li className={cn(`color__black`)}>
                                                <span className={cn('phone-list__icon-wrapper')}>
                                                    <FontAwesomeIcon icon={['solid', 'earth-europe']} fixedWidth/></span>https://www.auto-mgn.ru
                                                </li>
                                            </ul>
                                        </div>
                                        <div className={cn('table__cell', 'cell')}>
                                            ежедневно<br/>
                                            8:00-20:00
                                        </div>
                                    </div>
                                    <div className={cn('table__row')} style={{ "--my-repeat": 9 }}>
                                        <div className={cn('table__cell', 'cell')}>
                                            <img src={okreview_icon} alt="" width={14} height={14}
                                                 title="OKReview"/>OK REVIEW
                                        </div>
                                        <div className={cn('table__cell', 'cell')}>
                                            Аванта-Запад
                                        </div>
                                        <div className={cn('table__cell', 'cell')}>
                                            Аванта-Запад
                                        </div>

                                        <div className={cn('table__cell', 'cell')}>
                                            Московская область,<br/>
                                            Москва, пр-т<br/>
                                            Ленина, 26
                                        </div>
                                        <div className={cn('table__cell', 'cell')}>
                                            1841000031 Магазин розничной торговли
                                        </div>
                                        <div className={cn('table__cell', 'cell')}>
                                            <ul className={cn('phone-list')}>
                                                <li className={cn(`color__black`)}>
                                                <span className={cn('phone-list__icon-wrapper')}>
                                                    <FontAwesomeIcon icon={'mobile-screen'} fixedWidth/></span>+7 000 000-00-00 (горячая линия и заказ доставки)
                                                </li>
                                                <li className={cn(`color__black`)}>
                                                <span className={cn('phone-list__icon-wrapper')}>
                                                    <FontAwesomeIcon icon={'mobile-screen'} fixedWidth/></span>+7 000 000-00-00
                                                </li>
                                            </ul>
                                        </div>
                                        <div className={cn('table__cell', 'cell')}>
                                            <ul className={cn('phone-list')}>
                                                <li className={cn(`color__black`)}>
                                                <span className={cn('phone-list__icon-wrapper')}>
                                                    <FontAwesomeIcon icon={['solid', 'earth-europe']} fixedWidth/></span>https://www.auto-mgn.ru
                                                </li>
                                            </ul>
                                        </div>
                                        <div className={cn('table__cell', 'cell')}>
                                            ежедневно<br/>
                                            8:00-20:00
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </Sidebar>
    )
}

export default CompanyStatistics
/**
 * @typedef {string} AdPlatformType
 **/

import avito from "../images/platforms_icon_final/06.png";
import google from "../images/platforms_icon_final/08.png";
import yandex from "../images/platforms_icon_final/01.png";
import toGis from "../images/platforms_icon_final/02.png";
import otzovik from "../images/platforms_icon_final/04.png";
import flamp from "../images/platforms_icon_final/09.png";
import irecommend from "../images/platforms_icon_final/03.png";
import zoon from "../images/platforms_icon_final/07.png";
import vkontakte from "../images/platforms_icon_final/10.png";
import prodoctorov from "../images/platforms_icon_final/prodoctorov.png";
import docdoc from "../images/platforms_icon_final/docdoc.svg";
import napopravku from "../images/platforms_icon_final/napopravku.svg";
import yell from '../images/platforms_icon_final/yell.svg';
import website from '../images/platforms_icon_final/website.svg';

/**
 * @readonly
 * @enum {AdPlatformType}
 */
export const AD_PLATFORM_TYPE = {
    AVITO: 'avito',
    GOOGLE: 'google',
    YANDEX: 'yandex',
    DGIS: '2gis',
    OTZOVIK: 'otzovik',
    FLAMP: 'flamp',
    IRECOMMEND: 'irecommend',
    ZOON: 'zoon',
    OKREVIEW: 'okreview',
    VKONTAKTE: 'vkontakte',
    PRODOCTOROV: 'prodoctorov',
    DOCDOC: 'docdoc',
    NAPOPRAVKU: 'napopravku',
    YELL: 'yell',
    SITE: 'website'
};

export const AD_PLATFORM = [
    { value: AD_PLATFORM_TYPE.AVITO, name: 'Avito', shortName: 'Avito', ico: avito, color: '#aeb3b7', lightColor: '#c7cdd1'},
    { value: AD_PLATFORM_TYPE.GOOGLE, name: 'Google Maps', shortName: 'Google', ico: google, color: '#3b7dea', lightColor: '#669df8' },
    { value: AD_PLATFORM_TYPE.YANDEX, name: 'Яндекс.Карты', shortName: 'Яндекс', ico: yandex, color: '#ea1515', lightColor: '#eb8383' },
    { value: AD_PLATFORM_TYPE.DGIS, name: '2ГИС', shortName: '2ГИС', ico: toGis, color: '#38a749', lightColor: '#8edb9a' },
    { value: AD_PLATFORM_TYPE.OTZOVIK, name: 'Отзовик', shortName: 'Отзовик', ico: otzovik, color: '#a61c00', lightColor: '#d0988d' },
    { value: AD_PLATFORM_TYPE.FLAMP, name: 'Flamp', shortName: 'Flamp', ico: flamp, color: '#6d9eeb', lightColor: '#94b9f2' },
    { value: AD_PLATFORM_TYPE.IRECOMMEND, name: 'Irecommend', shortName: 'Irecommend', ico: irecommend, color: '#e69138', lightColor: '#e7bb8d' },
    { value: AD_PLATFORM_TYPE.ZOON, name: 'Zoon', shortName: 'Zoon', ico: zoon, color: '#b4a7d6', lightColor: '#d8ccf8' },
    { value: AD_PLATFORM_TYPE.VKONTAKTE, name: 'ВКонтакте', shortName: 'ВКонтакте', ico: vkontakte, color: '#c9daf8', lightColor: '#d6e0f4' },
    { value: AD_PLATFORM_TYPE.PRODOCTOROV, name: 'ПроДокторов', shortName: 'ПроДокторов', ico: prodoctorov, color: '#93c47d', lightColor: '#daf2cf' },
    { value: AD_PLATFORM_TYPE.SITE, name: 'Сайт', shortName: 'Сайт', ico: website, color: '#AACD99', lightColor: '#d2eec4' },
    { value: AD_PLATFORM_TYPE.DOCDOC, name: 'DocDoc', shortName: 'DocDoc', ico: docdoc, color: '#b7e1cd', lightColor: '#d3f2e4' },
    { value: AD_PLATFORM_TYPE.YELL, name: 'Yell', shortName: 'Yell', ico: yell, color: '#ed484a', lightColor: '#ed484a' },
    { value: AD_PLATFORM_TYPE.NAPOPRAVKU, name: 'НаПоправку', shortName: 'НаПоправку', ico: napopravku, color: '#f1c232', lightColor: '#eecb5e' },
];

export default class AdPlatform {
    constructor(adPlatform) {
        this._id = adPlatform.id || null;
        this._type = adPlatform.type || null;
        this._title = adPlatform.title || null;
        this._url = adPlatform.url || null;
    }

    /**
     * @return {int|null}
     */
    get id() {
        return this._id;
    }

    /**
     * @return {AdPlatformType|null}
     */
    get type() {
        return this._type;
    }

    /**
     * @return {string|null}
     */
    get title() {
        return this._title;
    }

    /**
     * @return {string|null}
     */
    get url() {
        return this._url;
    }
}

import React, {useEffect, useState} from 'react';
import cn from "classnames";
import {pluralize, sourcesForGeo} from "../../../../../helpers";
import {Sidebar} from "primereact/sidebar";
import {
    processing_icon,
    okreview_icon
} from "../../../../../helpers/icons";
import {inject, observer} from "mobx-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import moment from "moment"
import { geoDataStore, geoStore } from "../../../../../stores/forms/geo.store";

const DataSynchronizationInfo = inject( 'geoStore')(observer(({ geoStore, companyInfo}) => {

    const [sources, setSources] = useState([])

    useEffect(() => {
        setSources(sourcesForGeo())
    }, [])

    const findSourceByCode = (code) => {
        return sources.find(source => source.value === code)
    }

    return (
        <>
            <div className={cn('block', 'synchronization_info')}>
                <div className={cn('block__title')}>
                    <h3>Синхронизация данных</h3>
                </div>
                {(companyInfo.adplatforms.length > 0 && sources.length > 0) &&
                    <div className={cn('block__content')}>
                        {companyInfo.adplatforms.map((ad, key) => {
                            const {ico, name} = findSourceByCode(ad.type)
                            return (
                                <div className={cn('content')} key={key}>
                                    <div className={cn('content__title', 'yandex_map')}>
                                        <img src={ico} alt="" width={15} height={15} title={name}/>
                                        {name}
                                    </div>
                                    <div className={cn('content__status', 'success')}>Опубликован</div>

                                    <div className={cn('content__info', 'info__title')}>Последняя проверка:</div>
                                    <div className={cn('content__info')}>
                                        {geoStore.getStatusUpdate() === 'done'
                                            ?
                                            ad.source.checkedAt ? moment(ad.source.checkedAt).format("hh:mm, DD.MM.YYYY") : 'Неизвестно'
                                            :
                                            'Обновление'}
                                    </div>
                                    {geoStore.getStatusUpdate() === 'done' && <DataSynchronizationItem ad={ad} companyInfo={companyInfo}/>}
                                </div>
                            )
                        })}
                    </div>}
            </div>
        </>
    )
}))

const DataSynchronizationItem = inject('geoStore', 'geoDataStore', 'geoDiffStore')(observer(
    ({geoStore, geoDataStore, geoDiffStore, ad, companyInfo }) => {

        const [visibleCompareSideBar, setVisibleCompareSideBar] = useState(false);
        const [sources, setSources] = useState([])
        const [diffData, setDiffData] = useState(null)

        let adData = null;

        useEffect(() => {
            setSources(sourcesForGeo())
            adData = parseSourceDiff(ad.type)
            geoDataStore.getPlatformData(companyInfo.id, ad.type)
                .then(result => {
                    setDiffData(geoDiffStore.mergePlatformData(adData.difference.patch, result.items, ad.type))
                })
        }, [])

        const findSourceByCode = (code) => {
            return sources.find(source => source.value === code)
        }

        const parseSourceDiff = (source = 'yandex') => {
            return companyInfo.adplatforms.find(ad=> {
                return ad.type === source
            })
        }

        return (
            <>{geoStore.getStatusUpdate() === 'done' ? <>
                {ad.difference.count > 0 ?
                    <div
                        className={cn('content__info', 'warning')}
                        style={{cursor: 'pointer'}}
                        onClick={() => setVisibleCompareSideBar(true)}
                    >
                        <span className={cn('pi', 'pi-times')}></span>
                        {ad.difference.count} {pluralize(ad.difference.count, ['расхождение', 'расхождения', 'расхождений'], false)}
                        <span className={cn('pi', 'pi-arrow-right')}></span>
                    </div> :

                    <div className={cn('content__info', 'success')}>
                        <span className={cn('pi', 'pi-check')}></span>
                        Расхождений нет
                    </div>}

                {diffData !== null && <CompareSideBar
                    visibleCompareSideBar={visibleCompareSideBar}
                    setVisibleCompareSideBar={setVisibleCompareSideBar}
                    findSourceByCode={findSourceByCode}
                    source={ad.type}
                    geoData={parseSourceDiff(ad.type)}
                    diffData={diffData}
                />}
            </> : <></>}</>
        )
    }
))

const CompareSideBar = inject('geoStore', 'geoDataStore')(observer(({
                                                                        geoStore,
                                                                        visibleCompareSideBar,
                                                                        setVisibleCompareSideBar,
                                                                        findSourceByCode,
                                                                        source,
                                                                        geoData,
                                                                        diffData,
                                                                    }) => {

    const [data, setData] = useState(null)
    const [categories, setCategories] = useState([])

    useEffect(() => {
        setData(geoStore.form)
    }, [])

    useEffect(() => {
        if (data) {
            setCategories(getCategoryBySource(source))
        }
    }, [data])

    const getCategoryBySource = (source = 'yandex') => {
        const sp = data.setupPlace.find(sp => {
            return sp.code === 'legalInfoSetup'
        })

        const block = sp.blocks.find(s => s.source === source)
        return block.fields[0].fieldValue || []
    }

    return (

        <Sidebar
            visible={visibleCompareSideBar}
            position="bottom"
            onHide={() => setVisibleCompareSideBar(false)}
            className={cn('side-bar', 'compare-side-bar')}
            blockScroll={true}
            icons={() => (
                <button
                    className={cn('p-link', 'close-text')}
                    onClick={() => setVisibleCompareSideBar(false)}
                >
                    Закрыть
                </button>
            )}
        >
            <div className={cn('compare-side-bar__wrapper')}>
                {data?.title && <div className={cn('compare-side-bar__header')}>
                    <div className={cn('compare-side-bar__title')}>
                        <b>{data.title.value}</b><br/><span
                        style={{fontSize: '12px'}}>{data.address.value.postcode} {data.address.value.city} {data.address.value.street} {data.address.value.building}</span>
                    </div>
                    <div className={cn('compare-side-bar__subtitle')}>
                        <div className={cn('compare-side-bar__subtitle-text')}>
                            {geoData.difference.count} {pluralize(geoData.difference.count, ['расхождение', 'расхождения', 'расхождений'], false)}
                        </div>
                        <div className={cn('compare-side-bar__subtitle-date')}>
                            <img src={processing_icon} width={20} height={15} alt=""/>
                          {moment(geoData.source.checkedAt).format("hh:mm, DD MMMM YYYY")}
                        </div>
                    </div>
                </div>}
                <div className={cn('table', 'data-compare-table', 'company-data-compare-table')}>
                    <div className={cn('table__wrapper')}>
                        <div className={cn('table')}>
                            <div className={cn('table__header')}>
                                <div className={cn('table__row')} style={{"--my-repeat": 8}}>
                                    <div className={cn('table__cell')}>Источник</div>
                                    <div className={cn('table__cell')}>Название</div>
                                    <div className={cn('table__cell')}>Короткое название</div>
                                    <div className={cn('table__cell')}>Адрес</div>
                                    <div className={cn('table__cell')}>Категории</div>
                                    <div className={cn('table__cell')}>Телефоны, мессенджеры, эл.почта</div>
                                    <div className={cn('table__cell')}>Сайт и соцсети</div>
                                    <div className={cn('table__cell')}>График работы</div>
                                </div>
                            </div>
                            {diffData !== null && <div className={cn('table__body')}>
                                <div className={cn('table__row')} style={{"--my-repeat": 9}}>
                                    <div className={cn('table__cell', 'cell')}>
                                        <img src={findSourceByCode(source).ico} alt="" width={14} height={14}
                                             title={findSourceByCode(source).name}/>{findSourceByCode(source).name}
                                    </div>
                                    <div className={cn('table__cell', 'cell')}>
                                        <ShowValueString data={diffData.title} icon={null}/>
                                    </div>
                                    <div className={cn('table__cell', 'cell')}>
                                        <ShowValueString data={diffData.short_title} icon={null}/>
                                    </div>

                                    <div className={cn('table__cell', 'cell')}>
                                        <ShowAddressString data={diffData.address}/>
                                    </div>
                                    <div className={cn('table__cell', 'cell')}>
                                        <ShowValueCategory data={diffData.category}/>
                                    </div>
                                    <div className={cn('table__cell', 'cell')}>
                                        <ul className={cn('phone-list')}>
                                            {diffData.phones && <ShowValueList data={diffData.phones} icon={['solid', 'mobile-screen']}/>}
                                            {diffData.emails && <ShowValueList data={diffData.emails} icon={['regular', 'envelope']}/>}
                                            {diffData.whatsapp_phone && <ShowValueString data={diffData.whatsapp_phone} icon={['brands', 'whatsapp']}/>}
                                            {diffData.tg_link && <ShowValueString data={diffData.tg_link} icon={['brands', 'telegram']}/>}
                                            {diffData.viber_phone && <ShowValueString data={diffData.viber_phone} icon={['brands', 'viber']}/>}
                                        </ul>
                                    </div>
                                    <div className={cn('table__cell', 'cell')}>
                                        <ul className={cn('phone-list')}>
                                            {diffData.websites && <ShowValueList data={diffData.websites} icon={['solid', 'earth-europe']}/>}
                                            {diffData.vk_url && <ShowValueString data={diffData.vk_url} icon={['brands', 'vk']}/>}
                                            {diffData.youtube_link && <ShowValueString data={diffData.youtube_link} icon={['brands', 'youtube']}/>}
                                            {diffData.ok_link && <ShowValueString data={diffData.ok_link} icon={['brands', 'odnoklassniki']}/>}
                                            {diffData.twitter_link && <ShowValueString data={diffData.twitter_link} icon={['brands', 'x-twitter']}/>}
                                        </ul>
                                    </div>
                                    <div className={cn('table__cell', 'cell')}>
                                        <WorkTimeBlock data={diffData['work-time']}/>
                                    </div>

                                    </div>
                                    <div className={cn('table__row')} style={{"--my-repeat": 9}}>
                                        <div className={cn('table__cell', 'cell')}>
                                            <img src={okreview_icon} width={14} height={14} alt=''/>
                                            OK REVIEW
                                        </div>
                                        <div className={cn('table__cell', 'cell')}>{diffData.title.main}</div>

                                        <div className={cn('table__cell', 'cell')}>
                                            {diffData.short_title.main}
                                        </div>

                                        <div className={cn('table__cell', 'cell')}>
                                            {diffData.address?.main && <ul>
                                                <li>{diffData.address.main.country}</li>
                                                <li>{diffData.address.main.region}</li>
                                                <li>{diffData.address.main.city}</li>
                                                <li>{diffData.address.main.street} {diffData.address.main.building}</li>
                                                <li>{diffData.address.main.postcode}</li>
                                                <li>{diffData.address.main.comment}</li>
                                            </ul>}
                                        </div>
                                        <div className={cn('table__cell', 'cell')}>
                                            {diffData.category?.main?.length > 0 && diffData.category.main.map((item, index) => {
                                                return (
                                                    <span className={'category'} key={index}>
                                                    {item.title}
                                                        {index !== categories.length - 1 && ', '}
                                                </span>
                                                )
                                            })}
                                        </div>
                                        <div className={cn('table__cell', 'cell')}>
                                            <ul className={cn('phone-list')}>
                                                {diffData.phones?.main?.length > 0 && diffData.phones.main.map((item, index) => {
                                                    return (
                                                        <li key={index}>
                                                        <span
                                                            className={cn('phone-list__icon-wrapper')}><FontAwesomeIcon
                                                            icon={['solid', 'mobile-screen']}
                                                            fixedWidth/></span>{item.number}
                                                        </li>
                                                    )
                                                })}

                                                {diffData.emails?.main?.length > 0 && diffData.emails.main.map((item, index) => {
                                                    return (
                                                        <li key={index}>
                                                        <span
                                                            className={cn('phone-list__icon-wrapper')}><FontAwesomeIcon
                                                            icon={['regular', 'envelope']} fixedWidth/></span>{item}
                                                        </li>
                                                    )
                                                })}

                                                {diffData.whatsapp_phone?.main && <li>
                                                <span className={cn('phone-list__icon-wrapper')}><FontAwesomeIcon
                                                    icon={['brands', 'whatsapp']}
                                                    fixedWidth/></span>{diffData.whatsapp_phone.main}
                                                </li>}
                                                {diffData.tg_link?.main && <li>
                                                <span className={cn('phone-list__icon-wrapper')}><FontAwesomeIcon
                                                    icon={['brands', 'telegram']}
                                                    fixedWidth/></span>{diffData.tg_link.main}
                                                </li>}
                                                {diffData.viber_phone?.main && <li>
                                                <span className={cn('phone-list__icon-wrapper')}><FontAwesomeIcon
                                                    icon={['brands', 'viber']} fixedWidth/></span>
                                                    {diffData.viber_phone.main}
                                                </li>}
                                            </ul>
                                        </div>
                                        <div className={cn('table__cell', 'cell')}>
                                            <ul className={cn('phone-list')}>
                                                {diffData.websites?.main?.length > 0 && diffData.websites.main.map((item, index) => {
                                                    return (
                                                        <li key={index}>
                                                        <span className={cn('phone-list__icon-wrapper')}>
                                                            <FontAwesomeIcon icon={['solid', 'earth-europe']}
                                                                             fixedWidth/></span>
                                                            {item}
                                                        </li>
                                                    )
                                                })}
                                                {diffData.vk_url?.main && <li>
                                                <span className={cn('phone-list__icon-wrapper')}><FontAwesomeIcon
                                                    icon={['brands', 'vk']} fixedWidth/></span>{diffData.vk_url.main}
                                                </li>}
                                                {diffData.youtube_link?.main && <li>
                                                <span className={cn('phone-list__icon-wrapper')}><FontAwesomeIcon
                                                    icon={['brands', 'youtube']}
                                                    fixedWidth/></span>{diffData.youtube_link.main}</li>}
                                                {diffData.ok_link?.main && <li>
                                                <span className={cn('phone-list__icon-wrapper')}><FontAwesomeIcon
                                                    icon={['brands', 'odnoklassniki']}
                                                    fixedWidth/></span>{diffData.ok_link.main}</li>}
                                                {diffData.twitter_link?.main && <li>
                                                <span className={cn('phone-list__icon-wrapper')}><FontAwesomeIcon
                                                    icon={['brands', 'x-twitter']}
                                                    fixedWidth/></span>{diffData.twitter_link.main}</li>}
                                            </ul>
                                        </div>
                                        <div className={cn('table__cell', 'cell')}>
                                            <WorkTimeBlock data={diffData['work-time']} showMainOnly={true}/>
                                        </div>
                                    </div>
                                </div>}
                            </div>
                    </div>
                </div>
            </div>

        </Sidebar>
    )

}))



const setColorClass = (data) => {
    if(data.patch && data.patch.direction === 'incomming') {
        if(data.patch.action === 'add' || data.patch.action === 'change')
            return 'red'
    }
    else {
        return 'black'
    }
}

const getUniqueItems = (data) => {

    const uniqueItems = [];
    const seenIds = new Set();

    data.forEach(item => {
        if (item.color === 'red') {
            uniqueItems.push(item);
            seenIds.add(item.value.id);
        }
    });

    data.forEach(item => {
        if (item.color === 'black' && !seenIds.has(item.value.id)) {
            uniqueItems.push(item);
            seenIds.add(item.value.id);
        }
    });

    return uniqueItems
}

const getValue = (data) => {
    if(!!data.patch && data.patch?.direction === 'incomming') {
        return data.patch.value
    }
    else if(!!data.platform){
        return data.platform.value
    }
    else {
        return ''
    }
}

const ShowAddressString = ({data}) => {
    const [visible, setVisible] = useState(false);
    const [value, setValue] = useState(null);


    useEffect(() => {
        setValue(getValue(data))
    }, [])

    useEffect(()=>{
        setVisible(!!value)
    }, [value])

    return <>
        {visible && <ul className={cn(`color__${setColorClass(data)}`)}>
            <li>{value.country}</li>
            <li>{value.region}</li>
            <li>{value.city}</li>
            <li>{value.street} {value.building}</li>
            <li>{value.postcode}</li>
            <li>{value.comment}</li>
        </ul>}
    </>
}

const ShowValueString = ({data, icon = null}) => {
    const [visible, setVisible] = useState(false);
    const [value, setValue] = useState(null);

    useEffect(()=>{
        setValue(getValue(data))
    }, [])

    useEffect(()=>{
        setVisible(!!value)
    }, [value])

    return (<>
        {visible && icon ? <li className={cn(`color__${setColorClass(data)}`)}>
            <span className={cn('phone-list__icon-wrapper' )}>
                <FontAwesomeIcon icon={icon} fixedWidth/>
            </span>
            {value}
        </li> : <span className={cn(`color__${setColorClass(data)}`)}>{value}</span>}
    </>)
}

const ShowValueList = ({data, icon = ['solid', 'mobile-screen']}) => {
    const [list, setList] = useState([])


    useEffect(()=>{
        let tmpList = [];

        if(!!data.platform) {
            data.platform.value.map(item => {
                tmpList.push({
                    value: item,
                    color: 'black'
                })
            })
        }

        if(!!data.patch && data.patch?.direction === 'incomming') {
            data.patch.value.map(item => {
                tmpList.push({
                    value: item,
                    color: 'red'
                })
            })
        }
        setList(getUniqueItems(tmpList))

    }, [])

    return (<>
        {list.length > 0 && list.map((item, index) => {
            if(item.value.number !== '') {
                return (
                    <li key={index} className={cn(`color__${item.color}`)}>
                    <span
                        className={cn('phone-list__icon-wrapper')}><FontAwesomeIcon
                        icon={icon}
                        fixedWidth/></span>{item.value.number ?? item.value}
                    </li>
                )

            }
        })}
    </>)
}

const ShowValueCategory = ({data}) => {
    const [list, setList] = useState([])

    useEffect(() => {
        let tmpList = []

        if(!!data.platform) {
            data.platform.value.map(item => {
                tmpList.push({
                    value: item,
                    color: 'black'
                })
            })
        }

        if(!!data.patch && data.patch?.direction === 'incomming') {
            data.patch.value.map(item => {
                tmpList.push({
                    value: item,
                    color: 'red'
                })
            })
        }
        setList(getUniqueItems(tmpList))

    }, [])


    return (<>
        {list.length > 0 && list.map((item, index) => {
            return (
                <span className={cn('category', `color__${item.color}`)} key={index}>
                    {item.value.title}
                </span>
            )
        })}
    </>)
}

const WorkTimeBlock = ({data, showMainOnly = false}) => {
    const [workTime, setWorkTime] = useState(null)
    const [colored, setColored] = useState(false)

    const [isNotDiff, setIsNotDiff] = useState(false)
    const [isWork5Week, setIsWork5Week] = useState(false)

    const weekDay = {
        "1": "Пн",
        "2": "Вт",
        "3": "Ср",
        "4": "Чт",
        "5": "Пт",
        "6": "Сб",
        "7": "Вс"
    }

    useEffect(() => {

        if (!showMainOnly) {
            if(data.patch && data.patch.direction === 'incomming') {
                setWorkTime(data.patch.value)
                setColored(true)
            }
            else {
                setWorkTime(data.platform ? data.platform.value : [])
            }
        }
        else {
            setWorkTime(data.main)
        }

    }, [])

    useEffect(()=>{
        if (workTime !== null) {
            if (workTime.length === 7) {
                const diff = workTime.every((item, index, ar) => {
                    return JSON.stringify(item.work) === JSON.stringify(ar[0].work)
                })
                setIsNotDiff(diff)
            }

            if (workTime.length === 5) {
                const work5Week = workTime.map((item, index, ar) => {
                    if ([1, 2, 3, 4, 5].includes(item.day)) {
                        if (JSON.stringify(item.work) === JSON.stringify(ar[0].work)) {
                            return true
                        }
                    }
                })

                if ([...new Set(work5Week)].length === 1 && [...new Set(work5Week)][0] === true) {
                    setIsWork5Week(true)
                }
            }
        }

    }, [workTime])

    return (<>
        <div className={cn({'color__red': colored})}>
            {workTime !== null && isNotDiff ?

                <>ежедневно<br/>{workTime[0].work.start}-{workTime[0].work.finish}</>
                : isWork5Week ?
                    <>
                        Пн-Пт:
                        <br/>{workTime[0].work.start}-{workTime[0].work.finish}
                        <br/>
                        Сб-Вс:
                        <br/> выходной
                    </>
                    : <ul>
                        {workTime?.length > 0 && workTime.map((item, index) => {
                            return (
                                <li key={index}>
                                        <span style={{
                                            'width': '22px',
                                            'display': 'inline-block'
                                        }}>{weekDay[item.day]}:</span>
                                    <nobr>{item.work.start}-{item.work.finish}</nobr>
                                </li>
                            )
                        })}
                    </ul>
            }
        </div>
    </>)
}

export default inject('geoStore')(observer(DataSynchronizationInfo));
import React from "react";

const LinkToBlank = ({children, link}) => {
    return (
        <span
            onClick={(e) => {
                e.preventDefault()
                e.stopPropagation();

                let a = document.createElement("a");
                document.body.appendChild(a);
                a.href = link;
                a.target = "_blank";
                a.click();
                document.body.removeChild(a);
            }}
        >{children}</span>
    )
}

export default LinkToBlank;
import React, {useEffect, useRef} from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import cn from "classnames";
import am5locales_ru_RU from "@amcharts/amcharts5/locales/ru_RU";
import { toNumber } from "@amcharts/amcharts4/.internal/core/utils/Type";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

const Block2Chart = ({data = [], index}) => {
    let root = null;
    let container = useRef(null);

    const destroyChart = () => {
        root && root.dispose();
    }

    const initChart = (data = [],
                       index,
                       settings = []) => {
        am5.ready(function () {

            root = am5.Root.new(`chart2${index}`);

            root.locale = am5locales_ru_RU;

            root.setThemes([
                am5themes_Animated.new(root)
            ]);

            let chart = root.container.children.push(am5xy.XYChart.new(root, {
                panX: false,
                panY: false,
                layout: root.verticalLayout,
            }));

            chart.zoomOutButton.set("forceHidden", true);

            let xRenderer = am5xy.AxisRendererX.new(root, {
                minGridDistance: 30,
                minorGridEnabled: true,
                strokeOpacity: 0.1
            });
            xRenderer.labels.template.setAll({
                visible: true,
                paddingTop: 5,
                fontSize: 11,
            });

            xRenderer.grid.template.setAll({
                location: 1,
                visible: false,
            })

            let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
                categoryField: "month",
                renderer: xRenderer
            }));

            xAxis.data.setAll(data);

            let yRenderer = am5xy.AxisRendererY.new(root, {
                strokeOpacity: 0
            });

            yRenderer.labels.template.setAll({
                fontSize: 11,
                visible: true,
            });

            yRenderer.grid.template.setAll({
                location: 1,
                visible: true,
            });

            let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
                renderer: yRenderer,
                min: 0,
                calculateTotals: true,
            }));

            let legend = chart.children.push(am5.Legend.new(root, {
                centerX: am5.percent(50),
                x: am5.percent(50),
            }));

            legend.valueLabels.template.set("forceHidden", true);

            legend.markers.template.setAll({
                height:10,
                width:10,
            })

            legend.labels.template.setAll({
                fontSize:11,
                paddingRight:0
            })

            function makeSeries(name, fieldName, color, data, totals = false) {
                let series = chart.series.push(am5xy.ColumnSeries.new(root, {
                    name: name,
                    stacked: true,
                    xAxis: xAxis,
                    yAxis: yAxis,
                    valueYField: fieldName,
                    //valueYShow: "valueYTotalPercent",
                    categoryXField: "month",
                    paddingTop:15,
                }));

                if (!totals) {
                    series.bullets.push(function () {

                        let label = am5.Label.new(root, {
                            text: "{valueY}",
                            fontSize: '11px',
                            fill: '#373947',
                            centerY: am5.p50,
                            centerX: am5.p50,
                            populateText: true,
                            paddingTop:40,
                        })

                        label.adapters.add("text", function(text, target) {
                            const valueY = target.dataItem.get('valueY')
                            return valueY !== 0 ? valueY : '';
                        });

                        return am5.Bullet.new(root, {
                            sprite: label
                        });
                    });
                    legend.data.push(series);
                } else {
                    series.bullets.push(function () {
                        return am5.Bullet.new(root, {
                            sprite: am5.Label.new(root, {
                                text: "{valueYTotal}",
                                fontSize: '11px',
                                fill: '#373947',
                                centerY: 10,
                                centerX: am5.p50,
                                populateText: true
                            })
                        });
                    });
                }


                series.columns.template.setAll({
                    //width: 50,
                    maxWidth:45,
                    fill: color,
                    stroke: color,
                });

                series.data.setAll(data);
                series.appear();
                container.current = root;
            }

            settings.map((item, _) => {
                makeSeries(item.title.toString(), item.code.toString(), item.color.toString(), data);
            })
            makeSeries("none", "none", 'transparent', data, true);
            chart.appear(1000, 100);

            container.current = root;

        });
    }

    useEffect(() => {

        initChart(data.chartByScores,
            index,
            data.chartByScoresLegend
        )

        return () => destroyChart();
    }, [data]);


    return (
        <>
            <div className={cn('content chart__item')}>
                <div className={cn('item item__ico')}>
                    <img src={data.icon} alt={data.title} width={30} height={30}/>
                </div>
                <div className={cn('item item__digit')}>
                    <span>{data.reviews.toLocaleString('ru-RU')}</span>
                    <span className={cn('color__green', {'color__red': toNumber(data.diff) < 0})}>{data.diff}</span>
                </div>
                <div className={cn('item item__chart')} id={`chart2${index}`}></div>
            </div>
        </>
    )
}

export default Block2Chart;
import React, { useEffect, useState } from "react";
import cn from "classnames";
import { inject, observer } from "mobx-react";
import _ from "underscore";
import { FilterButtonSimple, FilterButton, CheckboxButton } from './FilterButton';
import { ToolTip } from "./ToolTip";
import SlideNav from "../pages/analytics/SlideNav";
import { useIsMount } from "../../hooks";
import './Filter.scss';
import OkDateRangePicker from "./OkDateRangePicker";

const TOOLTIP_TEXT = 'Фильтр периода применяется к показателю "Всего заявок за период". Значения остальных показателей в таблице формируются исходя из отфильтрованных заявок.'

const FilterApsBranch = inject('analyticsStore')(observer(
        ({ analyticsStore, loading, mobile = false }) => {
            const { branches, filterBranch, setFieldValue } = analyticsStore;
            const isMount = useIsMount();

            useEffect(() => {
                analyticsStore.getBranches();
            }, []);

            const setValue = _.debounce((field, data) => {
                if (!isMount) {
                    setFieldValue(field, data, 'filterBranch');
                }
            }, 800);

            const onChangeRangeDate = (date) => {
                analyticsStore.setDate({
                    from: date.startDate,
                    to: date.endDate,
                }, 'filterBranch');
            }

            const setOrientation = () => {
                return mobile === true ? 'vertical' : 'horizontal';
            }

            return (
                <>
                    <div className={cn('filter__wrapper')}>
                        <div className={'filter__item'}>

                            <OkDateRangePicker
                                onChangeDateHandler={onChangeRangeDate}
                                dateRange={filterBranch.date}
                                orientation={setOrientation()}
                            />

                            <div className={'tooltip__wrapper'}>
                                <ToolTip mobile={mobile}>
                                    {TOOLTIP_TEXT}
                                </ToolTip>
                            </div>

                        </div>

                        <div className={'filter__item'}>
                            {branches.length > 0 && <FilterButtonSimple
                                list={branches}
                                onChange={(data) => setValue('branches', data)}
                                onClear={() => setFieldValue('branches', [{ value: branches[0].value }], 'filterBranch')}
                                filter={filterBranch.branches}
                                defaultValue={{ value: branches[0].value }}
                                loading={loading}
                                mobile={mobile}
                                fixedWidth={false}
                                search={true}
                            />}
                        </div>
                    </div>
                </>
            )
        }
    )
);


const FilterCompanyList = inject('geoDataStore')(observer(({geoDataStore, loading}) => {
    const {statuses, types, filter,  setFieldValue, clearFilter}  = geoDataStore;
    const isMount = useIsMount();
    const [reset, setReset] = useState(false);

    const setValue = _.debounce((field, data) => {
        if (!isMount) {
            setFieldValue(field, data);
        }
    }, 10);

    const clear = () => {
        clearFilter()
        setReset(true)
    }

    return (
        <>
            <div className={cn('filter__wrapper')}>
                <div className={'filter__item'}>
                    <FilterButton
                        name='Статус'
                        list={statuses}
                        onChange={(data) => setValue('status', data)}
                        onClear={() => setFieldValue('status', [])}
                        filter={filter.status}
                        loading={loading}
                    />
                </div>
                <div className={'filter__item'}>
                    <FilterButton
                        name='Тип филиала'
                        list={types}
                        onChange={(data) => setValue('type', data)}
                        onClear={() => setFieldValue('type', [])}
                        filter={filter.type}
                        loading={loading}
                    />
                </div>
{/*
                <div className={'filter__item'}>
                    <CheckboxButton
                        name='Новая компания'
                        handlerClick={(checked) => {
                            setFieldValue('novelty', checked ? 1 : 0);
                        }}
                        reset={reset}
                        setReset={setReset}
                    />
                </div>
*/}
                <div
                    className={'filter__clear-btn'}
                    onClick={clear}
                >Очистить все
                </div>
            </div>
        </>
    )
}))

const FilterReview = inject('analyticsStore')(observer(
    ({ analyticsStore, loading, mobile = false }) => {
        const { branches, sources, filter, setFieldValue } = analyticsStore;
        const isMount = useIsMount();

        useEffect(() => {
            analyticsStore.getBranches();
            analyticsStore.getSources();
        }, [])

        const setValue = _.debounce((field, data) => {
            if (!isMount) {
                setFieldValue(field, data);
            }
        }, 800);

        const onChangeRangeDate = (date) => {
            analyticsStore.setDate({
                from: date.startDate,
                to: date.endDate,
            });
        }
        const setOrientation = () => {
            return mobile === true ? 'vertical' : 'horizontal';
        }

        const clear = () => {
            analyticsStore.clearFilter();
        }

        return (
            <>
                <div className={cn('filter__wrapper')}>
                    <div className={'filter__item'}>
                        <OkDateRangePicker
                            onChangeDateHandler={onChangeRangeDate}
                            dateRange={filter.date}
                            orientation={setOrientation()}
                        />
                    </div>
                    <div className={'filter__item'}>
                        <FilterButton
                            name='Филиал'
                            list={branches}
                            onChange={(data) => setValue('branches', data)}
                            onClear={() => setFieldValue('branches', [])}
                            filter={filter.branches}
                            loading={loading}
                            search={true}
                        />
                    </div>
                    <div className={'filter__item'}>
                        <FilterButton
                            name='Источник'
                            list={sources}
                            onChange={(data) => setValue('sources', data)}
                            onClear={() => setFieldValue('sources', [])}
                            filter={filter.sources}
                            loading={loading}
                        />
                    </div>
                    <div
                        className={'filter__clear-btn'}
                        onClick={clear}
                    >Очистить все
                    </div>
                </div>
            </>
        )
    }
));

const FilterAps = inject('analyticsStore', 'openHref')(observer(
        ({ analyticsStore, openHref, mobile = false }) => {
            const { filter } = analyticsStore;

            const handleChangeNav = (href) => {
                openHref(href)
            }

            const onChangeRangeDate = (date) => {
                analyticsStore.setDate({
                    from: date.startDate,
                    to: date.endDate,
                });
            }
            const setOrientation = () => {
                return mobile === true ? 'vertical' : 'horizontal';
            }

            return (
                <>
                    <div className={cn('filter__wrapper')}>
                        <div className={'filter__item'}>
                            <SlideNav
                                handleChangeNav={handleChangeNav}
                                data={[
                                    { 'branches': 'Филиалы' },
                                    { 'managers': 'Сотрудники' }
                                ]}
                                value={'branches'}
                            />
                        </div>

                        <div className={'filter__item'}>
                            <OkDateRangePicker
                                onChangeDateHandler={onChangeRangeDate}
                                dateRange={filter.date}
                                orientation={setOrientation()}
                            />

                            <div className={'tooltip__wrapper'}>
                                <ToolTip
                                    mobile={mobile}
                                >
                                    {TOOLTIP_TEXT}
                                </ToolTip>
                            </div>
                        </div>

                    </div>
                </>
            )
        }
    )
);

const FilterTraffic = inject('analyticsStore')(observer(({ analyticsStore, loading, mobile = false }) => {
        const { groupByList, filterTraffic, setFieldValue, branches } = analyticsStore;
        const isMount = useIsMount();

        useEffect(() => {
            analyticsStore.getBranches();
        }, []);

        const setValue = _.debounce((field, data) => {
            if (!isMount) {
                setFieldValue(field, data, 'filterTraffic');
            }
        }, 800);

        const onChangeRangeDate = (date) => {
            analyticsStore.setDate({
                from: date.startDate,
                to: date.endDate,
            }, 'filterTraffic');
        }

        const setOrientation = () => {
            return mobile === true ? 'vertical' : 'horizontal';
        }

        return (
            <>
                <div className={cn('filter__wrapper')}>
                    <div className={'filter__item'}>
                        <OkDateRangePicker
                            onChangeDateHandler={onChangeRangeDate}
                            dateRange={filterTraffic.date}
                            orientation={setOrientation()}
                        />
                    </div>

                    <div className={'filter__item'}>
                        <FilterButton
                            name='Филиал'
                            list={branches}
                            onChange={(data) => setValue('branches', data)}
                            onClear={() => setFieldValue('branches', [], 'filterTraffic')}
                            filter={filterTraffic.branches}
                            loading={loading}
                            search={true}
                        />
                    </div>

                    <div className={'filter__item'}>
                        <FilterButtonSimple
                            list={groupByList}
                            onChange={(data) => setValue('groupby', data)}
                            onClear={() => setFieldValue('groupby', [{ value: 'day' }], 'filterTraffic')}
                            filter={filterTraffic.groupby}
                            loading={loading}
                            defaultValue={{ value: 'day' }}
                        />
                    </div>
                </div>
            </>
        );
    }
));

const FilterQr = inject('analyticsStore')(observer(
    ({ analyticsStore, mobile = false }) => {
        const { filterQr } = analyticsStore;

        const onChangeRangeDate = (date) => {
            analyticsStore.setDate({
                from: date.startDate,
                to: date.endDate,
            }, 'filterQr');
        }

        const setOrientation = () => {
            return mobile === true ? 'vertical' : 'horizontal';
        }

        return (
            <>
                <div className={cn('filter__wrapper')}>
                    <div className={'filter__item'}>
                        <OkDateRangePicker
                            onChangeDateHandler={onChangeRangeDate}
                            dateRange={filterQr.date}
                            orientation={setOrientation()}
                        />
                    </div>

                </div>

            </>
        )
    }
));

const FilterDateCompare = inject('analyticsStore')(observer(
    ({ analyticsStore, loading }) => {

        const isMount = useIsMount();

        const { filterDateCompare, setFieldValue, branches } = analyticsStore;

        const ratingList = [
            {value: 'rating', name: 'Рейтинг'},
            {value: 'reviews', name: 'Отзывы'},
            {value: 'scores', name: 'Оценки'},
            {value: 'answersResponse', name: 'Охват ответов'},
            {value: 'avgTimeReply', name: 'Cкорость ответов'},
        ]

        useEffect(() => {
            analyticsStore.getBranches();
            analyticsStore.getSources();
        }, []);

        const onChangeRangeDate = (date) => {
            analyticsStore.setDate({
                from: date.startDate,
                to: date.endDate,
            }, 'filterDateCompare');
        }

        const setValue = _.debounce((field, data) => {
            if (!isMount) {
                setFieldValue(field, data, 'filterDateCompare');
            }
        }, 800);

        return (
            <>
                <div className={cn('filter__wrapper')}>
                    <div className={'filter__item'}>
                        <OkDateRangePicker
                            onChangeDateHandler={onChangeRangeDate}
                            dateRange={filterDateCompare.date}
                            orientation={'horizontal'}
                        />
                    </div>
                    <div className={'filter__item'}>
                        <FilterButton
                            name='Филиал'
                            list={branches}
                            onChange={(data) => setValue('branches', data)}
                            onClear={() => setFieldValue('branches', [], 'filterDateCompare')}
                            filter={filterDateCompare.branches}
                            loading={loading}
                            search={true}
                        />
                    </div>

                    <div className={'filter__item'}>
                        <FilterButtonSimple
                            list={ratingList}
                            onChange={(data) => setFieldValue('openBlock', data, 'filterDateCompare')}
                            onClear={() => setFieldValue('openBlock', [{ value: 'rating' }], 'filterDateCompare')}
                            filter={filterDateCompare.openBlock}
                            loading={loading}
                            sort={false}
                            defaultValueToTop={false}
                            defaultValue={{ value: 'rating' }}
                        />
                    </div>

                    <div className={'filter__item'}>
                        <span
                            className={cn('filter__clear-btn', 'margin0')}
                            onClick={() => {
                                analyticsStore.clearFilter('filterDateCompare')
                            }}
                        >Очистить все</span>
                    </div>
                </div>
            </>
        )
    }
));


export {
    FilterReview,
    FilterAps,
    FilterApsBranch,
    FilterTraffic,
    FilterQr,
    FilterDateCompare,
    FilterCompanyList
};

import React from "react";
import { NavLink } from "react-router-dom";
import "./GeoTabs.scss";

const GeoTabs = () => {
    return (
        <div className={'tabs'}>
            <ul>
                <li>
                    <NavLink to='/company-list'>Список филиалов</NavLink>
                </li>
                <li>
                    <NavLink to='/company-statistics'>Статистика</NavLink>
                </li>
            </ul>
        </div>
    );
}

export default GeoTabs;

import React, { useEffect, useState } from "react";
import { Select, Tag } from "antd";
import "./OkTags.scss";

const OkTags = ({ options, defaultValue, onChange, loading }) => {
    const [selected, setSelected] = useState(defaultValue);

    useEffect(() => {
        //TODO временное, переделать на ref
        let elem = document.getElementsByClassName('ant-select-selection-search-input')
        for (let i = 0; i <= elem?.length-1; i++) {
            if (elem[i]) {
                elem[i].setAttribute("placeholder", "# Тегировать")
                elem[i].setAttribute("maxlength", "50")
                elem[i].addEventListener('keydown', function(e) {
                    if(e.code === 'Backspace') {
                        if(elem[i].value <= 1) {
                            e.preventDefault()
                            e.stopPropagation();
                        }
                    }

                });
            }
        }
    }, []);

    return (
        <>
            <Select
                mode="tags"
                variant="borderless"
                className="MyTag"
                popupClassName="MyTagDropdown"
                showSearch={true}
                suffixIcon={null}
                style={{
                    width: "100%"
                }}
                loading={loading}
                tagRender={tagRender}
                maxTagTextLength={20}
                labelInValue={true}
                value={selected}
/*
                onSelect={(value)=>{
                    let clearValue = value.value.trim().replace(/[^(\d\sa-zA-Zа-яА-Я!@#$%:,^.&;*\(\)-_+<>\[\]'“|{}\]/?~`—«»=)]*!/g, '')
                    if (clearValue.length > 0) {
                        onChange(clearValue);
                    }
                }}
*/
                onChange={(value) => {
                    let clearValues = value
                        .filter((entry) => (/\S/.test(entry.value))) // убираем значения только из пробелов
                        .filter((entry) => (/[(A-Za-zА-Яа-я\d)]/.test(entry.value))) // убираем значения без букв и цифр
                        .map(entry => (
                            {
                                label: entry.label,
                                // убираем все кроме букв цифр и знаков препинания
                                value: entry.value.replace(/[^(\d\sa-zA-Zа-яА-Я!@#$%:,^.&;*\(\)-_+<>\[\]'“|{}\]/?~`—«»=)]*/g, '').trim(),
                                key: entry.key,
                            }
                        ))
                    setSelected(clearValues);
                    onChange(clearValues);
                }}
                options={options}
/*
                onBlur={(e)=>{
                    console.log('onBlur ',e)
                }}
*/
            />
        </>
    );
}

const tagRender = (props) => {
    const { label, closable, onClose } = props;

    const onPreventMouseDown = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    return (
        <>
            {label.trim().length > 0 && <Tag
                onMouseDown={onPreventMouseDown}
                closable={closable}
                onClose={onClose}
                className={'OkItemTag'}
            >
                {label.trim()}
            </Tag>
            }
        </>
    );
};

export default OkTags;
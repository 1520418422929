import React, { useState } from "react";
import cn from "classnames";
import {Button} from "primereact/button";
import AdPlatformSideBar from "./AdPlatformSideBar";
import OkSplitButton from "./OkSplitButton";
import {inject, observer} from "mobx-react";
import OkInputText from "../../../common/OkInputText";
import {LegalInfoCategoryMulti} from "./bloks/LegalInfoCategory";
//import {ContactInfoCategoryMulti} from "./bloks/ContactInfo";
import {WebsiteMulti} from "./bloks/Website";

import "./setup.scss"
import {InputTextarea} from "primereact/inputtextarea";
import {ContactPhoneMulti} from "./bloks/ContactPhone";
import {ContactEmailMulti} from "./bloks/ContactEmail";
import {toJS} from "mobx";

const Setup = ({geoStore, fields, code, disabled, showAddButton=true, showRemoveButton=true}) => {
    const currentPlace = geoStore.getCurrentSetup(code);

    const [visibleRight, setVisibleRight] = useState(false)

    const changeActivity = (source) => {
        currentPlace.blocks.map(block => {
            block.active = block.source === source;
        });
    }

    const getActivityBlockIndex = () => {
        return currentPlace.blocks.find(block => block.active === true)
    }

    const removeAll = () => {
        geoStore.removeSetupPlace(code);
    }
    const removeByIndex = () => {
        geoStore.removeSetupPlaceItem(code, getActivityBlockIndex().source)
    }

    return (
        <>
            {!currentPlace.blocks.length ? <div className={cn("btn-field")}>
                    <Button
                        disabled={disabled}
                        label='Настроить площадки'
                        icon="pi pi-plus"
                        iconPos="left"
                        onClick={() => {
                            setVisibleRight(true)
                        }}
                    />
                </div>
                :
                <div className={cn("place-setup")}>
                    <div className={cn("place-setup__header")}>
                        <ul className={cn("header")}>
                            {currentPlace.blocks.map((source, key) => (
                                <li
                                    key={key}
                                    onClick={() => {
                                        !source.active && changeActivity(source.source)
                                    }}
                                    className={cn({"header__active": source.active})}
                                >{source.name}</li>
                            ))}

                            {showAddButton && <li
                                className={cn("add-link")}
                                onClick={() => {
                                    setVisibleRight(true)
                                }}>+ Добавить
                            </li>}

                        </ul>
                        {showRemoveButton && <div className={cn("delete-btn")}>
                            <OkSplitButton
                                model={
                                    [
                                        {
                                            name: "Текущую вкладку",
                                            command: () => {
                                                removeByIndex()
                                            }
                                        },
                                        {
                                            name: "Весь блок настроек",
                                            command: () => {
                                                removeAll()
                                            }
                                        },
                                    ]
                                }
                            />
                        </div>}

                    </div>
                    {currentPlace.blocks.map((block, key) => (
                        <div key={key+currentPlace.code+block.source} className={cn("place-setup__body", "body", {"body__active": block.active})} id={currentPlace.code+block.source}>
                            <div className={cn('block__form')}>
                                {currentPlace.code === 'contactInfoSetup' &&
                                    <>
                                        <ContactEmailMulti
                                            code={currentPlace.code}
                                            source={block.source}
                                            field={fields[1]}
                                        />

                                    </>
                                }
                                {currentPlace.code === 'phoneInfoSetup' &&
                                    <>
                                        <ContactPhoneMulti
                                            code={currentPlace.code}
                                            source={block.source}
                                            field={fields[0]}
                                        />
                                    </>
                                }
                                {currentPlace.code === 'socialMediaSetup' &&
                                    <>
                                        <WebsiteMulti
                                            code={currentPlace.code}
                                            source={block.source}
                                            field={fields[0]}
                                        />
                                    </>
                                }
                                {currentPlace.code === 'descriptionSetup' &&
                                    <>
                                        {block.fields.map((field, key) => (
                                            <div className={cn("form__row")} key={key}>
                                                <div className={cn('textarea-field')}>
                                                    <div className={cn("p-float-label")}>
                                                        <InputTextarea
                                                            id={currentPlace.code + '_' + block.source + '_' + field.fieldName}
                                                            name={currentPlace.code + '_' + block.source + '_' + field.fieldName}
                                                            required={field.required}
                                                            value={field.fieldValue}
                                                            maxLength={field.maxlength}
                                                            onChange={(e)=>{
                                                                geoStore.setSetupFieldValue(currentPlace.code, block.source, field.fieldName, e.target.value)
                                                            }}
                                                        />
                                                        <label
                                                            htmlFor={block.source + '_' + field.fieldName}>{field.fieldLabel}<i className={'additional'}><i className={'additional'}>(не более {field.maxlength} символов)</i></i>
                                                            {field.required && <span>*</span>}
                                                        </label>
                                                    </div>
                                                    <div className={cn('maxLength__wrapper')}>
                                                        <span className={cn('maxLength')}>{field.fieldValue.length}/{field.maxlength}</span>
                                                    </div>

                                                </div>
                                            </div>
                                        ))}
                                    </>
                                }
                                <div className={cn("form__row")}>
                                    {block.fields.map((field, key2) => {
                                        let object = null;
                                        switch (field.fieldType) {
                                            case 'text':
                                                object = (<div className={cn('text-field')}>
                                                    <div className={cn("p-float-label")}>
                                                        <OkInputText
                                                            id={currentPlace.code + '_' + block.source + '_' + field.fieldName}
                                                            type="text"
                                                            name={currentPlace.code + '_' + block.source + '_' + field.fieldName}
                                                            required={field.required}
                                                            value={field.fieldValue}
                                                            onChange={(e)=>{
                                                                geoStore.setSetupFieldValue(currentPlace.code, block.source, field.fieldName, e.target.value)
                                                            }}
                                                        />
                                                        <label
                                                            htmlFor={block.source + '_' + field.fieldName}>{field.fieldLabel}
                                                            {field.required && <span>*</span>}
                                                        </label>
                                                    </div>
                                                </div>)
                                                break;
                                            case 'dropdown':

                                                object = (
                                                    <>
                                                        <LegalInfoCategoryMulti
                                                            code={currentPlace.code}
                                                            source={block.source}
                                                            name={field.fieldName}
                                                        />
                                                    </>
                                                );
                                                break;
                                        }
                                        return <div key={key2}>{object}</div>
                                    })}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            }

            <AdPlatformSideBar
                visibleRight={visibleRight}
                setVisibleRight={setVisibleRight}
                fields={fields}
                code={code}
            />
        </>
    )
}

export default inject("geoStore")(observer(Setup));
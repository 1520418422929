import cn from "classnames";
import React from "react";
import {
    isMobile
} from "react-device-detect";

const SMMessageBlock = () => {
    return (

            <div className={cn('col', {'col__w50': !isMobile}, {'mr_0': !isMobile}, {'ml_0': isMobile})}>
                <div className={cn('messages')}>
                    <div className={cn('messages__text')}>
                        <div className={cn('text')}>
                            В ближайшее время здесь появятся полезные новости и статьи от OK REVIEW, не пропустите!
                        </div>
                    </div>
                </div>
            </div>
    );
}

export default SMMessageBlock;

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import {getDetailDataByPlatform, serialize} from "../helpers";
import authService from "./auth";
import moment from "moment";
import _ from "underscore";

class AnalyticsChartService {

//TODO надо бы вынести куда-нибудь, вдруг еще понадобятся цвета
    statuses = [
        {code: 'duplicate', color: '#cccccc', title: 'Дубликат'},
        {code: 'collecting_review', color: '#ffd966', title: 'В процессе сбора отзыва'},
        {code: 'review_moderation', color: '#d7fbe5', title: 'Отзыв на модерации'},
        {code: 'positive_review_with_text', color: '#34a853', title: 'Позитивный отзыв с текстом'},
        {code: 'positive_review_without_text', color: '#8CD8AB', title: 'Позитивный отзыв без текста'},
        {code: 'negative_new', color: '#e8564a', title: 'Негатив перехвачен'},
        {code: 'negative_inprogress', color: '#fe9c8c', title: 'Негатив в работе'},
        {code: 'negative_processed', color: '#f6c4bc', title: 'Негатив обработан'},
        {code: 'no_review_received', color: '#fee8e4', title: 'Отзыв не получен'},
        {code: 'incorrect_contact', color: '#e69138', title: 'Неверный контакт'},
        {code: 'refusal_leave_review', color: '#cf6b0c', title: 'Отказ оставить отзыв'},
        {code: 'review_rejected', color: '#c0b6dc', title: 'Отзыв отклонен'},
    ];

    groups = [
        {code: 'application_processing', color: '#ffd966', title: 'В работе'},
        {code: 'positive_review', color: '#34a853', title: 'Позитивный отзыв'},
        {code: 'negative_review', color: '#fe9c8c', title: 'Негатив перехвачен'},
        {code: 'other', color: '#cccccc', title: 'Прочее'},
    ]

    parseDateForQuery(date) {
        return serialize({
            date: {
                'from': moment(new Date(date.from)).format("YYYY-MM-DD").toString(),
                'to': moment(new Date(date.to)).format("YYYY-MM-DD").toString(),
            }
        })
    }

    parseFilterToApi = (filter = {}) => {
        let newObj = {
            date: {
                'from': moment(new Date(filter.date.from)).format("YYYY-MM-DD").toString(),
                'to': moment(new Date(filter.date.to)).format("YYYY-MM-DD").toString(),
            },
        };

        _.mapObject(filter, (val, key) => {
            if (_.isArray(val) && val.length > 0) {
                newObj[key] = filter[key].map(val => val.value).join(',');
            }
        });

        return newObj;
    }

//Заявки и конверсия в отзыв
    getDataForChartConversionReview(date) {
        const queryString = this.parseDateForQuery(date)

        return authService.get(
            `/applications/chart/conversion/review?${queryString}`,
        )
            .then((result) => {

                let empty = true
                let data = []
                let total = 0;
                result.items.map(item => {
                    if (item.counters.apps !== 0) {
                        empty = false;
                    }
                    if (total < item.counters.apps) {
                        total = item.counters.apps
                    }

                    data.push({
                        month: moment(item.date.month, 'M').format('MMM'),
                        visits: item.counters.apps,
                        percentages: item.counters.apps ? Math.round(item.counters.reviews / item.counters.apps * 100) : 0
                    })
                })
                return Promise.resolve({items: data, empty, total})
            })
            .catch(e => e);
    }

//Распределение заявок по статусам
    getDataForChartDistributionStatus(date) {

        const queryString = this.parseDateForQuery(date)
        return authService.get(
            `/applications/chart/distribution/status?${queryString}`,
        )
            .then((result) => {

                let legendGroup = []
                let i = 0;
                let statusesObject = {}
                let groupsObject = {}


                for (let key in result.statuses) {
                    statusesObject[key] = 0
                }

                for (let key in result.groups) {
                    groupsObject[key] = 0

                    const {color} = this.groups.find(elem => elem.code === key)

                    legendGroup.push({
                        code: key,
                        title: result.groups[key].name,
                        color: color || '#cccccc'
                    })
                    i++;
                }

                let templateGroupObject = {}

                const data = [];

                result.items.map(item => {

                    for (let key in statusesObject) {
                        templateGroupObject = findKeyInGroup(result.groups, key, item.distribution[key]?.apps)
                    }

                    const copy = {
                        ...templateGroupObject,
                        ...{
                            'none': 0,
                            'month': moment(item.date.month, 'M').format('MMM')
                        }
                    }
                    data.push(copy)

                    resetGroups()
                });

                let total = 0;

                data.map(item => {
                    let totalStatus = 0;
                    for (let key in item) {
                        if (key !== 'month') {
                            totalStatus = totalStatus + item[key];
                        }
                    }

                    if (total < totalStatus) {
                        total = totalStatus;
                    }

                })

                function resetGroups() {
                    for (let key in result.groups) {
                        groupsObject[key] = 0
                    }
                }

                function findKeyInGroup(groups, key, value = 0) {
                    let result = groupsObject
                    for (let groupKey in groups) {
                        const found = groups[groupKey].statuses.find(st => st === key)
                        if (found) {
                            result[groupKey] = result[groupKey] + value
                        }

                    }
                    return result
                }

                return Promise.resolve({
                    data,
                    legend: legendGroup,
                    total
                })

            })
            .catch(e => e);
    }

//Конверсия в переход
    getDataForChartConversionClick(date) {
        const queryString = this.parseDateForQuery(date)
        return authService.get(
            `/applications/chart/conversion/click?${queryString}`,
        )
            .then((result) => {

                let empty = true
                let total = 0
                const data = []
                result.items.map(item => {
                    if (item.counters.apps !== 0) {
                        empty = false;
                    }

                    data.push({
                        month: moment(item.date.month, 'M').format('MMM'),
                        visits: item.counters.apps,
                        percentages: item.counters.apps ? Math.round(item.counters.clicks / item.counters.apps * 100) : 0
                    })
                })

                data.map(item => {
                    if (total < item.percentages) {
                        total = item.percentages;
                    }
                })

                return Promise.resolve({
                    data,
                    total,
                    empty
                })
            })
            .catch(e => e);

    }

//Распределение переходов по площадкам
    getDataForChartDistributionSource(date) {

        const queryString = this.parseDateForQuery(date)
        return authService.get(
            `/applications/chart/distribution/source?${queryString}`,
        )
            .then((result) => {

                let legend = [];
                let i = 0;
                let statusesObject = {}

                for (let key in result.sources) {
                    statusesObject[key] = 0
                    const {color} = getDetailDataByPlatform(key)

                    legend.push({
                        code: key,
                        title: result.sources[key].name,
                        color: color || '#38a749'
                    })
                    i++;
                }

                let templateObject = {
                    ...{
                        "month": "",
                        "none": 0,
                    },
                    ...statusesObject
                }

                const data = [];

                result.items.map(item => {
                    templateObject['month'] = moment(item.date.month, 'M').format('MMM');

                    for (let key in statusesObject) {
                        templateObject[key] = item.distribution[key]?.apps ? item.distribution[key].apps : 0;
                    }
                    const copy = {...templateObject}
                    data.push(copy)
                });

                let total = 0;

                data.map(item => {
                    let totalStatus = 0;
                    for (let key in item) {
                        if (key !== 'month') {
                            totalStatus = totalStatus + item[key];
                        }
                    }

                    if (total < totalStatus) {
                        total = totalStatus;
                    }

                })

                return Promise.resolve({
                    data,
                    legend,
                    total
                })
            })
            .catch(e => e);
    }

//Рейтинг (Аналитика / Динамика рейтинга) block1
    getDataSourceRating(filter) {

        const queryString = serialize(this.parseFilterToApi(filter))

        return authService.get(
            `/extreview/reviews/chart/distribution/source/rating?${queryString}`,
        )
            .then((result) => {

                let data = []

                if (result.sources) {
                    for (let key in result.sources) {

                        let chart = []
                        let max = 0
                        let min = 10000000

                        result.items.map(item => {
                            const rating = item.distribution[key]?.rating || 0

                            if (max < rating) {
                                max = rating
                            }

                            if (min > rating) {
                                min = rating
                            }

                            chart.push({
                                month: moment(item.date.month, 'M').format('MMM'),
                                value: item.distribution[key]?.rating ? item.distribution[key]?.rating : 0
                            })
                        })

                        const {ico, color, lightColor} = getDetailDataByPlatform(key);

                        data.push(
                            {
                                code: key,
                                name: result.sources[key].name,
                                icon: ico,
                                color: color,
                                lightColor: lightColor,
                                rating: result.sources[key].rating.close,
                                diff: result.sources[key].rating.diff > 0 ? `+${result.sources[key].rating.diff.toLocaleString('ru-RU')}` : result.sources[key].rating.diff.toLocaleString('ru-RU'),
                                chart,
                                maxValue: max,
                                minValue: min
                            }
                        )
                    }
                }

                return Promise.resolve(data)
            })
            .catch(e => e);
    }

// Отзывы - (Аналитика / Динамика отвеченных отзывов) block2
// Доля отзывов с ответом, % - (Аналитика / Динамика отвеченных отзывов) block3
    getDataSourceReplies(filter) {
        const queryString = serialize(this.parseFilterToApi(filter))

        return authService.get(
            `/extreview/reviews/chart/distribution/source/replies?${queryString}`,
        )
            .then((result) => {
                let data = []

                if (result.sources) {
                    const chartByScoresLegend = [
                        {
                            code: 'score0',
                            title: 'Нет оценок',
                            color: '#cccccc',
                        },
                        {
                            code: 'score1',
                            title: '1',
                            color: '#ea4335',
                        },
                        {
                            code: 'score2',
                            title: '2',
                            color: '#f59296',
                        },
                        {
                            code: 'score3',
                            title: '3',
                            color: '#fdcf44',
                        },
                        {
                            code: 'score4',
                            title: '4',
                            color: '#f7e095',
                        },
                        {
                            code: 'score5',
                            title: '5',
                            color: '#a3ca8a',
                        },
                    ]

                    for (let key in result.sources) {

                        let chart = [];
                        let chartByScores = [];

                        let max = 0;
                        let min = 10000000

                        let maxPercentages = 0;
                        let minPercentages = 10000;


                        result.items.map(item => {

                            const reviews = item.distribution[key]?.reviews || 0

                            if (max < reviews) {
                                max = reviews;
                            }

                            if (min > reviews) {
                                min = reviews
                            }


                            const percentages = reviews ? Math.round(item.distribution[key]?.answers / reviews * 100) : 0
                            if (maxPercentages < percentages) {
                                maxPercentages = percentages;
                            }
                            if (minPercentages > percentages) {
                                minPercentages = percentages
                            }


                            chart.push({
                                month: moment(item.date.month, 'M').format('MMM'),
                                value: item.distribution[key]?.reviews ? item.distribution[key]?.reviews : 0,
                                answers: item.distribution[key]?.answers ? item.distribution[key]?.answers : 0,
                                percentages
                            })


                            chartByScores.push({
                                month: moment(item.date.month, 'M').format('MMM'),
                                'score0': item.distribution[key]?.scores ? item.distribution[key]?.scores.reviews[0] : 0,
                                'score1': item.distribution[key]?.scores ? item.distribution[key]?.scores.reviews[1] : 0,
                                'score2': item.distribution[key]?.scores ? item.distribution[key]?.scores.reviews[2] : 0,
                                'score3': item.distribution[key]?.scores ? item.distribution[key]?.scores.reviews[3] : 0,
                                'score4': item.distribution[key]?.scores ? item.distribution[key]?.scores.reviews[4] : 0,
                                'score5': item.distribution[key]?.scores ? item.distribution[key]?.scores.reviews[5] : 0,
                                'none': 0,
                            })
                        })
                        const {ico, color, lightColor} = getDetailDataByPlatform(key);
                        data.push(
                            {
                                code: key,
                                name: result.sources[key].name,
                                icon: ico,
                                color: color,
                                lightColor: lightColor,
                                reviews: result.sources[key].reviews.close,
                                diff: result.sources[key].reviews.diff > 0 ? `+${result.sources[key].reviews.diff.toLocaleString('ru-RU')}` : result.sources[key].reviews.diff.toLocaleString('ru-RU'),
                                chart,
                                chartByScores,
                                chartByScoresLegend,
                                maxValue: max,
                                minValue: min,
                                minPercentages: (minPercentages === maxPercentages) ? 0 : minPercentages,
                                maxPercentages,

                            }
                        )
                    }
                }

                return Promise.resolve(data)
            })
            .catch(e => e);
    }

    getDataSourceReplyTimes(filter) {
        const queryString = serialize(this.parseFilterToApi(filter))

        return authService.get(
            `/extreview/reviews/chart/distribution/source/reply-times?${queryString}`,
        )
            .then((result) => {
                let data = []

                if (result.sources) {
                    for (let key in result.sources) {

                        let chart = []
                        let max = 0;

                        result.items.map(item => {
                            if (max < item.distribution[key]?.avg) {
                                max = item.distribution[key]?.avg;
                            }

                            chart.push({
                                month: moment(item.date.month, 'M').format('MMM'),
                                value: item.distribution[key]?.avg ? Math.round(item.distribution[key]?.avg / 60 / 60) : 0,
                            })
                        })
                        const {ico, color, lightColor} = getDetailDataByPlatform(key);

                        data.push(
                            {
                                code: key,
                                name: result.sources[key].name,
                                icon: ico,
                                color: color,
                                lightColor: lightColor,
                                chart,
                                maxValue: Math.round(max / 60 / 60),
                            }
                        )
                    }
                }
                return Promise.resolve(data)

            })
            .catch(e => e);
    }

//Заявки и конверсия в отзыв
    initChartRequestsAndConversion(elementId = null, data = []) {
        if (!elementId || !data.length) return;

        am5.ready(function () {
            let root = am5.Root.new(elementId);

            let chart = root.container.children.push(am5xy.XYChart.new(root, {
                panX: false,
                panY: false,
                paddingLeft: 10,
                paddingRight: 10,
                layout: root.verticalLayout
            }));

            let xRenderer = am5xy.AxisRendererX.new(root, {
                minGridDistance: 10,
                minorGridEnabled: true,
                strokeOpacity: 0.1
            });

            let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
                categoryField: "month",
                renderer: xRenderer
            }));

            xRenderer.grid.template.setAll({
                location: 1,
                visible: false,
            })

            xRenderer.labels.template.setAll({
                paddingTop: 5,
                fontSize: 11,
            });

            xAxis.data.setAll(data);

            let yRenderer = am5xy.AxisRendererY.new(root, {});

            yRenderer.labels.template.setAll({
                fontSize: 11,
                visible: false,
            });


            yRenderer.grid.template.setAll({
                location: 1,
                visible: false,
            });

            let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
                renderer: yRenderer,
            }));

            let paretoAxisRenderer = am5xy.AxisRendererY.new(root, {opposite: true});

            paretoAxisRenderer.labels.template.setAll({
                fontSize: 11,
                visible: false,
            });

            let paretoAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
                renderer: paretoAxisRenderer,
                min: 0,
                max: 100,
                strictMinMax: true
            }));

            paretoAxisRenderer.grid.template.set("forceHidden", true);
            paretoAxis.set("numberFormat", "#'%");

            let series = chart.series.push(am5xy.ColumnSeries.new(root, {
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: "visits",
                categoryXField: "month"
            }));

            series.columns.template.setAll({
                width: 45,
                //tooltipText: "{valueY}",
                tooltipY: 0,
                fill: "#8BD8AB",
                strokeOpacity: 0,
                cornerRadiusTL: 0,
                cornerRadiusTR: 0
            });

            series.bullets.push(function () {
                return am5.Bullet.new(root, {
                    locationY: 1,
                    sprite: am5.Label.new(root, {
                        text: "{valueY}",
                        //fill: root.interfaceColors.get("alternativeText"),
                        fontSize: 11,
                        centerY: 25,
                        centerX: am5.p50,
                        populateText: true
                    })
                });
            });

            let paretoSeries = chart.series.push(
                am5xy.SmoothedXLineSeries.new(root, {
                    xAxis: xAxis,
                    yAxis: paretoAxis,
                    valueYField: "percentages",
                    categoryXField: "month",
                    stroke: "#0FAFE2",
                    maskBullets: true,
                    fill: "#0FAFE2",
                })
            );

            paretoSeries.bullets.push(function () {
                return am5.Bullet.new(root, {
                    locationY: 1,
                    sprite: am5.Label.new(root, {
                        text: "{valueY}%",
                        fontSize: 11,
                        centerY: 25,
                        centerX: am5.p50,
                        populateText: true
                    })
                });
            });

            paretoSeries.bullets.push(function () {
                return am5.Bullet.new(root, {
                    locationY: 1,
                    sprite: am5.Circle.new(root, {
                        radius: 3,
                        fill: "#0FAFE2",
                        stroke: "#0FAFE2",
                    })
                })
            });

            series.data.setAll(data);
            paretoSeries.data.setAll(data);
        });
    }

//Распределение заявок по статусам
//Распределение переходов по площадкам
    initChartStackedColumn(elementId, data = [], totalHeight = 1200, settings = []) {
        if (!elementId || !data.length) return;

        am5.ready(function () {

            let root = am5.Root.new(elementId);

            let chart = root.container.children.push(am5xy.XYChart.new(root, {
                panX: false,
                panY: false,
                paddingLeft: 10,
                paddingRight: 10,
                //paddingBottom: -4,
                layout: root.verticalLayout
            }));

            let xRenderer = am5xy.AxisRendererX.new(root, {
                minGridDistance: 30,
                minorGridEnabled: true,
                strokeOpacity: 0.1
            });

            xRenderer.labels.template.setAll({
                paddingTop: 5,
                visible: true,
                fontSize: "11px"
            });

            xRenderer.grid.template.setAll({
                visible: false,
                location: 1
            })

            let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
                categoryField: "month",
                renderer: xRenderer,
            }));

            xAxis.data.setAll(data);

            let yRenderer = am5xy.AxisRendererY.new(root, {
                strokeOpacity: 0
            })

            yRenderer.labels.template.setAll({
                visible: false,
            });

            yRenderer.grid.template.setAll({
                visible: false,
                minorGridEnabled: false,
            })

            let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
                min: 0,
                // макс высота столбца (нужно чтобы влез total)
                max: totalHeight, //надо устанавливать динамически от пришедших данных
                strictMinMax: true,
                calculateTotals: true,
                renderer: yRenderer,
            }));

            function makeSeries(name, fieldName, color, data, totals = false) {
                let series = chart.series.push(am5xy.ColumnSeries.new(root, {
                    name: name,
                    stacked: true,
                    xAxis: xAxis,
                    yAxis: yAxis,
                    valueYField: fieldName,
                    //valueYShow: "valueYTotalPercent",
                    categoryXField: "month",
                }));


                if (!totals) {
                    series.bullets.push(function () {

                        let label = am5.Label.new(root, {
                            text: "{valueY}",
                            fontSize: '11px',
                            fill: '#373947',
                            centerY: am5.p50,
                            centerX: am5.p50,
                            populateText: true
                        })

                        label.adapters.add("text", function (text, target) {
                            const valueY = target.dataItem.get('valueY')
                            return valueY !== 0 ? valueY : '';
                        });

                        return am5.Bullet.new(root, {
                            sprite: label
                        });
                    });
                } else {
                    series.bullets.push(function () {
                        return am5.Bullet.new(root, {
                            sprite: am5.Label.new(root, {
                                text: "{valueYTotal}",
                                fontSize: '11px',
                                fill: '#373947',
                                centerY: 23,
                                centerX: am5.p50,
                                populateText: true
                            })
                        });
                    });
                }


                series.columns.template.setAll({
                    width: 50,
                    fill: color,
                    stroke: color,
                });

                series.data.setAll(data);
            }


            settings.map((item, _) => {
                makeSeries(item.code.toString(), item.code.toString(), item.color.toString(), data);
            })
            makeSeries("none", "none", 'transparent', data, true);

        })

    }

//Конверсия в переход
    initChartConversionToConversion(elementId = null, data = [], totalHeight = 100) {
        if (!elementId || !data.length) return;

        am5.ready(function () {
            let root = am5.Root.new(elementId);

            let chart = root.container.children.push(am5xy.XYChart.new(root, {
                panX: false,
                panY: false,
                paddingLeft: 10,
                paddingRight: 10,
                layout: root.verticalLayout
            }));


            let xRenderer = am5xy.AxisRendererX.new(root, {
                minGridDistance: 10,
                minorGridEnabled: true,
                strokeOpacity: 0.1
            });

            let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
                categoryField: "month",
                renderer: xRenderer
            }));

            xRenderer.grid.template.setAll({
                location: 1,
                visible: false,
            })

            xRenderer.labels.template.setAll({
                paddingTop: 5,
                fontSize: 11,
            });

            xAxis.data.setAll(data);

            let paretoAxisRenderer = am5xy.AxisRendererY.new(root, {opposite: false});

            paretoAxisRenderer.labels.template.setAll({
                fontSize: 11,
                visible: false,
            });

            paretoAxisRenderer.grid.template.setAll({
                visible: false,
                minorGridEnabled: false,
            })

            let paretoAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
                renderer: paretoAxisRenderer,
                min: 0,
                max: totalHeight,
                strictMinMax: true
            }));

            paretoAxisRenderer.grid.template.set("forceHidden", true);
            paretoAxis.set("numberFormat", "#'%");

            let series = chart.series.push(
                am5xy.SmoothedXLineSeries.new(root, {
                    xAxis: xAxis,
                    yAxis: paretoAxis,
                    valueYField: "percentages",
                    categoryXField: "month",
                    stroke: "#EF8D78",
                    maskBullets: true,
                    fill: "#EF8D78",
                })
            );

            series.fills.template.setAll({
                visible: true,
                opacity: 0.2
            });

            series.bullets.push(function () {
                return am5.Bullet.new(root, {
                    locationY: 1,
                    sprite: am5.Label.new(root, {
                        text: "{valueY}%",
                        fontSize: 11,
                        centerY: 25,
                        centerX: am5.p50,
                        populateText: true
                    })
                });
            });

            series.bullets.push(function () {
                return am5.Bullet.new(root, {
                    locationY: 1,
                    sprite: am5.Circle.new(root, {
                        //tooltipText: "{valueY}%",
                        radius: 3,
                        fill: "#EF8D78",
                        stroke: "#EF8D78",
                    })
                })
            });

            series.data.setAll(data);

        });
    }

    initChartReviews(elementId, data = [], fillColor = '#8BD8AB', visibleLabels = false, maxHeight = 100) {
        if (!elementId || !data.length) return;

        am5.ready(function () {
            let root = am5.Root.new(elementId);

            let chart = root.container.children.push(am5xy.XYChart.new(root, {
                panX: false,
                panY: false,
                paddingLeft: 0,
                paddingRight: 0,
                paddingTop: 0,
                paddingBottom: 0,

                layout: root.verticalLayout,
            }));

            let xRenderer = am5xy.AxisRendererX.new(root, {
                minGridDistance: 10,
                minorGridEnabled: true,
                strokeOpacity: 0
            });

            let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
                categoryField: "month",
                renderer: xRenderer
            }));

            xRenderer.grid.template.setAll({
                location: 1,
                visible: false,
            })

            xRenderer.labels.template.setAll({
                visible: visibleLabels,
                paddingTop: 1,
                fontSize: 11,
            });

            xAxis.data.setAll(data);

            let yRenderer = am5xy.AxisRendererY.new(root, {});

            yRenderer.labels.template.setAll({
                fontSize: 11,
                visible: false,
            });

            yRenderer.grid.template.setAll({
                location: 1,
                visible: false,
            });

            let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
                renderer: yRenderer,
                min: 0,
                max: maxHeight,
            }));

            let series = chart.series.push(am5xy.ColumnSeries.new(root, {
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: "value",
                categoryXField: "month"
            }));

            series.columns.template.setAll({
                width: 45,
                //tooltipText: "{valueY}",
                tooltipY: 0,
                fill: fillColor,
                strokeOpacity: 0,
                cornerRadiusTL: 0,
                cornerRadiusTR: 0
            });

            series.bullets.push(function () {
                return am5.Bullet.new(root, {
                    locationY: 1,
                    sprite: am5.Label.new(root, {
                        text: "{valueY}",
                        //fill: root.interfaceColors.get("alternativeText"),
                        fontSize: 11,
                        centerY: 22,
                        centerX: am5.p50,
                        populateText: true
                    })
                });
            });

            series.data.setAll(data);
        });
    }

    initChartRating(elementId, data = [], fillColor = '#8BD8AB', strokeColor = '#8BD8AB', visibleLabels = false, visibleBullets = false, postfix = '%', maxHeight = 102, nameField = 'value') {
        if (!elementId || !data.length) return;

        am5.ready(function () {
            let root = am5.Root.new(elementId);

            let chart = root.container.children.push(am5xy.XYChart.new(root, {
                panX: false,
                panY: false,
                paddingLeft: 0,
                paddingRight: 0,
                paddingTop: 0,
                paddingBottom: 0,
                layout: root.verticalLayout,
                focusable: true,
            }));


            let xRenderer = am5xy.AxisRendererX.new(root, {
                minGridDistance: 10,
                minorGridEnabled: true,
                strokeOpacity: 0
            });

            let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
                categoryField: "month",
                renderer: xRenderer
            }));

            xRenderer.grid.template.setAll({
                location: 1,
                visible: false,
            })

            xRenderer.labels.template.setAll({
                paddingTop: 0,
                fontSize: 11,
                visible: visibleLabels,
            });

            xAxis.data.setAll(data);


            let yAxisRenderer = am5xy.AxisRendererY.new(root, {opposite: false});

            yAxisRenderer.labels.template.setAll({
                fontSize: 11,
                visible: false,
            });

            yAxisRenderer.grid.template.setAll({
                visible: false,
                minorGridEnabled: false,
            })

            let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
                renderer: yAxisRenderer,
                min: 0,
                max: maxHeight,
            }));

            let series = chart.series.push(
                am5xy.SmoothedXLineSeries.new(root, {
                    xAxis: xAxis,
                    yAxis: yAxis,
                    valueYField: nameField,
                    categoryXField: "month",
                    stroke: strokeColor,
                    maskBullets: true,
                    fill: fillColor
                })
            );

            series.fills.template.setAll({
                visible: true,
                //opacity: 0.2
            });

            if (visibleBullets) {
                series.bullets.push(function () {
                    return am5.Bullet.new(root, {
                        locationY: 1,
                        sprite: am5.Label.new(root, {
                            text: "{valueY}" + postfix,
                            fontSize: 11,
                            centerY: am5.p100,
                            centerX: am5.p50,
                            populateText: true,
                        })
                    });
                });

                series.bullets.push(function () {
                    return am5.Bullet.new(root, {
                        locationY: 1,
                        sprite: am5.Circle.new(root, {
                            //tooltipText: "{valueY}%",
                            radius: 3,
                            fill: fillColor,
                            stroke: strokeColor,
                        })
                    })
                });

            }
            series.data.setAll(data);
        });

    }

}

const analyticsChartService = new AnalyticsChartService();
export default analyticsChartService;
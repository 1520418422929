import { observable, action } from "mobx";
import moment from "moment";
//TODO  переделать под глобальный фильтр

import reviewsService from "../services/review";

class GlobalFilterStore {
    @observable branches = [];

    @observable sources = [];

    _reviewsService;

    constructor() {
        this._reviewsService = reviewsService;
    }

    getBranches() {
        this._reviewsService
            .getBranch()
            .then(data => {
                this.branches = data.items?.map(item => ({
                    value: item.id,
                    name: item.title,
                }))
            });
    }

    getSources() {
        this._reviewsService
            .getSources()
            .then(data => {
                this.sources = data.items?.map(item => {
                    return {
                        value: item.id,
                        name: item.title,
                    }
                })
            });
    }

}

class FilterStore extends GlobalFilterStore {

    @observable filter = {
        branch_id: [
            { value: 0 }
        ],
        date: {
            from: moment().subtract(1, 'month').toString(),
            to: moment().toString(),
        },
        manager_id: [
            { value: 0 }
        ],
        status_id: [],
        scores: [],
        query: '',
        sort: {
            column: 'time',
            order: 'desc'
        },
        page: {
            size: 10,
            number: 1
        }
    }

    oldFilter = {};

    datatableFields = [
        'page',
        'sort'
    ];

    disabledFields = [
        'query',
        'status_id',
        'scores',
    ]

    @observable numberOfChanges = 0;
    @observable numberOfChangesDatatable = 0;
    @observable visibleNewNegative = false;

    @observable isNewNegativeOn = false;

    constructor() {
        super();
    }

    @action setNewNegativeOn = (state) => {
        this.isNewNegativeOn = state;
    }

    @action resetOldFilter = () => {
        this.filter = Object.assign({}, this.oldFilter);
    }

    @action saveFilter = () => {
        this.oldFilter = Object.assign({}, this.filter);
    }

    @action clear = () => {
        this.filter = {
            branch_id: [
                { value: 0 }
            ],
            date: {
                from: moment().subtract(1, 'month').toString(),
                to: moment().toString(),
            },
            manager_id: [
                { value: 0 }
            ],
            status_id: [],
            scores: [],
            query: '',
            sort: {
                column: 'time',
                order: 'desc'
            },
            page: {
                ...this.filter.page,
                ...{number: 1}
            }
        }

    }

    @action getNewNegative = (statusId = [], dateFrom = null) => {
        this.filter = {
            branch_id: [
                { value: 0 }
            ],
            date: {
                'from': moment(dateFrom).toString(),
                'to': moment().toString()
            },
            manager_id: [
                { value: 0 }
            ],
            status_id: statusId,
            scores: [],
            query: '',
            sort: {
                column: 'time',
                order: 'desc'
            },
            page: {
                size: 10,
                number: 1
            }
        };
    }

    @action setVisibleNewNegative = (value) => {
        this.visibleNewNegative = value;
    }


//TODO заготовка для рефакторинга setFieldValue
    @action setFieldValue2 = (fieldName, fieldValue) => {
        if (this.filter.hasOwnProperty(fieldName)) {
            this.filter[fieldName] = fieldValue;
        }
    }


    @action setFieldValue = (fieldName, fieldValue, action = true) => {

        if (this.filter.hasOwnProperty(fieldName)) {
            this.filter[fieldName] = fieldValue;
//TODO проверить нужно ли нам  массив disabledFields если у метода есть параметр action?
            if (action === true) {
                if (this.disabledFields.indexOf(fieldName) !== -1) {
                    return;
                }

                if (this.datatableFields.indexOf(fieldName) !== -1) {
                    this.numberOfChangesDatatable++;
                } else {
                    this.numberOfChanges++;
                }

            }
        }
    }

    @action setPageSize = (size) => {
        this.filter.page.size = size;
    }

    @action setPage = (number) => {
        this.filter.page.number = number;
    }

    @action setDefaultManager = (id) => {
        this.filter.manager_id = new Array({value: id});
    }

    @action setDefaultService = (id) => {
        this.filter.branch_id = new Array({value: id});
    }


}

const filterStore = new FilterStore();
const globalFilterStore = new GlobalFilterStore();
export {
    filterStore,
    globalFilterStore
}

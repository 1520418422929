import React, { useEffect, useState } from "react";
import cn from "classnames";
import Tabs from "../Tabs";
import { FilterTraffic } from "../../../common/Filter";
import { MobileChartTraffic } from "./charts/ChartTraffic";
import { AD_PLATFORM_TYPE } from "../../../../models/ad-platform";
import yandex from '../../../../images/platforms_icon_final/01.png';
import toGis from '../../../../images/platforms_icon_final/02.png';

import { inject, observer } from "mobx-react";

const TrafficMobile = inject('analyticsStore')(observer(
    ({ analyticsStore }) => {

        const [loading, setLoading] = useState(true);
        const [chartData, setChartData] = useState(null);
        const { filterTraffic, setFieldValue, sources } = analyticsStore;
        const [chartSource, setChartSource] = useState(null);

        useEffect(() => {
            analyticsStore.getSources();
            setLoading(false);
        }, []);

        useEffect(() => {
            analyticsStore.getTraffic()
                .then(result => {
                    setChartData(result);
                    setLoading(false);
                });
        }, [JSON.stringify(filterTraffic)]);

        useEffect(() => {
            setChartSource(
                sources.find(item => (item.value === filterTraffic.sources[0].value))
            );
        }, [sources]);

        const getPeriod = () => filterTraffic.groupby.map(item => item.value);

        const onSelectSource = (source) => {
            setChartSource(source);
            setFieldValue('sources', [{ value: source.value }], 'filterTraffic');
        }

        return (
            <>
                <div className={cn('page__analytics-mobile', 'page')}>
                    <div className={cn('title')}>
                        <h1>Аналитика</h1>
                    </div>
                    <div className={cn('analytics-tabs')}>
                        <Tabs />
                    </div>
                    <div className={cn('analytics-filter')}>
                        <FilterTraffic loading={loading} mobile={true} />
                    </div>
                    {loading && <div className={'loader'} />}
                    {chartData && <div className={cn('analytics-table', 'table')}>
                        <div className={cn('table__wrapper')}>
                            <MobileChartTraffic
                                source={chartSource}
                                data={chartData}
                                period={getPeriod()}
                            />
                        </div>
                    </div>}
                    {sources.length > 0 && chartData && <div className={cn('analytics-footer')}>
                        <SourcesFooter
                            onSelectSource={onSelectSource}
                            sources={sources}
                            selected={filterTraffic.sources[0].value}
                        />
                    </div>}
                </div>
            </>
        );
    }
));

/**
 *
 * @type {[{ico: *, name: string, value: (AD_PLATFORM_TYPE|AdPlatformType)},{ico: *, name: string, value: (AD_PLATFORM_TYPE|AdPlatformType)}]}
 */
const sourcesModel = [
    { value: AD_PLATFORM_TYPE.YANDEX, name: 'Yandex', ico: yandex },
    { value: AD_PLATFORM_TYPE.DGIS, name: '2Gis', ico: toGis },
];

const SourcesFooter = ({ onSelectSource, sources, selected = null }) => {
    return (
        <div className={cn('analytics-carousel')}>
            <ul>
                {
                    sources.map((item, key) => {
                        const show = sourcesModel.find(val => val.value === item.code)
                        if(show) {
                            return (
                                <li key={key} onClick={() => onSelectSource(item)}>
                                    <img src={show.ico} alt="" width={45} height={45} title={show.name}
                                         className={cn({ 'active': (item.value === selected) })}
                                    />
                                </li>
                            );
                        }
                    })
                }
            </ul>
        </div>
    );
}


export default TrafficMobile;

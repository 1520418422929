import React from "react";
import { Button } from "primereact/button";
import "./AccessDenied.scss"

const AccessDenied = () => {

    return (
        <div className={'page__access-denied'}>
            <div className="centered-block">
                <h1>Доступ запрещен</h1>
                <h2>Доступ к этой странице запрещен</h2>
                <p>Попробуйте повторить действие или обратитесь в поддержу.</p>
                <br/>
                <div className={'error__button'}>
                    <Button label='Вернуться на главную' onClick={() => {
                        window.location.href = '/';
                    }}/>
                </div>
            </div>
        </div>
    )
}

export default AccessDenied
import axios from 'axios';
import { delete_cookie } from 'sfcookies'

class AuthService {

    static get RTOKEN_INVALID_ERROR_CODE() {
        return [8];
    }

    constructor() {
        this.user = {};

        this.prevUrl = null;

        this.isAuthorized = false;
        this.accessToken = null;
        this.refreshToken = null;
        this.accessTs = null;
        this.refreshRequest = null;

        this.remember = this.getRemember();

        this.client = axios.create({
            baseURL: process.env.REACT_APP_apiUrl,
        });

        this.client.interceptors.request.use(config => {

                if (!this.accessToken) {
                    return config;
                }

                const newConfig = {
                    headers: {},
                    ...config
                };

                newConfig.headers.Authorization = `Bearer ${this.accessToken}`;
                return newConfig
            },
            e => Promise.reject(e));

        this.client.interceptors.response.use(
            r => r,
            async error => {

                if (!this.refreshToken || error.response.status !== 401 || error.config.retry) {
                    throw error;
                }

                if (!this.refreshRequest) {
                    if (AuthService.RTOKEN_INVALID_ERROR_CODE.includes(error.response.data.error.data.code)) {
                        this.removeAppStorage();
                        window.location.replace('/login');
                    }

                    let bodyFormData = new FormData();
                    bodyFormData.append('grant_type', 'refresh_token');
                    bodyFormData.append('refresh_token', `${this.refreshToken}`);

                    this.refreshRequest = this.client.post('/auth/login', bodyFormData, {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                    });
                }

                const { data } = await this.refreshRequest;

                this.accessToken = data.data.access_token;
                this.refreshToken = data.data.refresh_token;
                this.accessTs = data.data.expires_in;
                this.isAuthorized = true;
                this.saveInAppStorage(data.data);

                const newRequest = {
                    ...error.config,
                    retry: true,
                };
                this.refreshRequest = null;
                return this.client(newRequest);
            }
        );

        window
            .addEventListener(
                'storage',
                (ev) => {
                    if (ev.key === process.env.REACT_APP_localStorageName) {
                        const data = localStorage.getItem(process.env.REACT_APP_localStorageName);

                        if (data) {
                            const { refresh_token, access_token } = JSON.parse(data);

                            this.refreshToken = refresh_token;
                            this.accessToken = access_token;
                        }

                    }
                }
            );
    }

    setPrevUrl = (prevUrl) => {
        this.prevUrl = prevUrl
    }

    getPrevUrl = () => {
        return this.prevUrl
    }

    delPrevUrl = () => {
        this.prevUrl = null;
    }

    setRemember = (remember) => {
        if (remember === true) {
            document.cookie = "remember=true;path=/;max-age=3600000;";
            this.remember = true;
        }
    }

    getRemember = () => {
        const name = 'remember';
        let matches = document.cookie.match(new RegExp(
            "(?:^|; )" + name.replace(/([.$?*|{}()[]\\\/+^])/g, '\\$1') + "=([^;]*)"
        ));
        return matches ? decodeURIComponent(matches[1]) : false;
    }

    delRemember = () => {
        delete_cookie('remember');
    }

    getAppStorage() {
        return (this.remember) ? localStorage : sessionStorage;
    }

    jsonToFormUrl(data) {
        let formBody = [];
        for (let property in data) {
            let encodedKey = encodeURIComponent(property);
            let encodedValue = encodeURIComponent(data[property]);
            formBody.push(encodedKey + "=" + encodedValue);
        }
        return formBody.join("&");
    }

    /**
     * внешняя авторизация по auth_code
     * @param auth_code
     * @returns {Promise<unknown>|boolean}
     */
    authAccessToken(auth_code) {
        if(!auth_code) return false;
        const formBody = this.jsonToFormUrl({code: auth_code})

        return new Promise((resolve, reject) => {
            fetch(`${process.env.REACT_APP_apiUrl}/auth/access_token`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    body: formBody,
                }
            )
                .then(response => {
                    return response.json();
                })
                .then(result => {
                    if (result.statusCode === 200) {
                        let data = result.data;
                        this.accessToken = data.access_token;
                        this.refreshToken = data.refresh_token;
                        this.accessTs = data.expires_in;
                        this.isAuthorized = true;
                        this.setRemember(true)
                        this.saveInAppStorage(data);
                        resolve();
                    } else {
                        reject(result);
                    }
                })
                .catch((e) => {
                    reject(e);
                })
        });
    }

    /**
     * @param {{password: *, username: *}} data
     * @param {boolean} remember
     */
    auth(data, remember) {
        const formBody = this.jsonToFormUrl({ ...data, ...{ 'grant_type': 'password' } })

        return new Promise((resolve, reject) => {
            fetch(`${process.env.REACT_APP_apiUrl}/auth/login`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    body: formBody,
                }
            )
                .then(response => {
                    return response.json();
                })
                .then(result => {
                    if (result.statusCode === 200) {
                        let data = result.data;
                        this.accessToken = data.access_token;
                        this.refreshToken = data.refresh_token;
                        this.accessTs = data.expires_in;
                        this.isAuthorized = true;
                        this.setRemember(remember);

                        this.saveInAppStorage(data);
                        resolve();
                    } else {
                        reject(result);
                    }
                })
                .catch((e) => {
                    reject(e);
                })
        });
    }

    async refresh() {
        try {
            let bodyFormData = new FormData();

            bodyFormData.append('grant_type', 'refresh_token');
            bodyFormData.append('refresh_token', `${this.refreshToken}`);

            const response = await this.client.post('/auth/login', bodyFormData, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
            });

            const result = await response.data;
            if (result.statusCode === 200) {
                let data = result.data;
                this.accessToken = data.access_token;
                this.refreshToken = data.refresh_token;
                this.accessTs = data.expires_in;
                this.isAuthorized = true;
                this.saveInAppStorage(data);

                return data;
            } else {
                throw result;
            }

        } catch (e) {
            return e
        }
    }

    logout() {
        return new Promise((resolve, reject) => {
            fetch(`${process.env.REACT_APP_apiUrl}/auth/logout`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        'refresh_token': this.refreshToken
                    }),
                }
            )
                .then(response => {
                    return response.json();
                })
                .then(result => {
                    if (result.statusCode === 200) {
                        this.clearAuthorization();
                        resolve();
                    } else {
                        reject(result);
                    }
                })
                .catch((e) => {
                    reject(e);
                })
        });
    }

    loadFromAppStorage() {

        const data = this.getAppStorage().getItem(process.env.REACT_APP_localStorageName);

        return new Promise((resolve, reject) => {
            if (data) {
                const storageData = JSON.parse(data);
                this.accessToken = storageData.access_token;
                this.refreshToken = storageData.refresh_token;
                this.accessTs = storageData.expires_in;

                this.isAuthorized = this.isAccessValid();
//TODO когда будет проверка авторизации в каждом запросе, сделать отдельный метод
                if (!this.isAccessValid() && this.refreshToken) {
                    return this.refresh().then(() => {
                        resolve();
                    });
                }
                resolve();
            } else {
                reject();
            }
        });
    }

    removeAppStorage() {
        this.getAppStorage().removeItem(process.env.REACT_APP_localStorageName);
    }

    saveInAppStorage(data) {
        const nowTs = Math.ceil(Date.now() / 1000) + data.expires_in
        this.getAppStorage().setItem(process.env.REACT_APP_localStorageName, JSON.stringify({
            'access_token': data.access_token,
            'refresh_token': data.refresh_token,
            'expires_in': nowTs,
        }));
    }

    clearAuthorization() {
        this.removeAppStorage();
        this.delRemember();
        this.user = {};
        this.isAuthorized = false;
        this.accessToken = null;
        this.refreshToken = null;
        this.accessTs = null;
        this.remember = this.getRemember();

    }

    isAccessValid() {
        const nowTs = Math.ceil(Date.now() / 1000);
        return this.accessToken && nowTs < this.accessTs - 90;
    }

    /**
     *
     * @param {String} url
     * @param {String} data
     * @returns {boolean}
     */
    sendBeacon (url, data) {
        return navigator.sendBeacon(process.env.REACT_APP_apiUrl+url, data);
    }

    async getWithHeaders(url, etag = null) {
        try {
            let customHeaders =  { 'Content-Type': 'application/json' }
            if(etag) {
               customHeaders = {
                   ...customHeaders,
                   ...{'If-None-Match': `${etag}`}
               }
            }

            const response = await this.client.get(url, { headers: customHeaders });

            const {data, headers, status} = await response;

            switch (status) {
                case 200:
                    return {result:data.data, headers: headers, status:200};
                default:
                    throw data;
            }
        } catch (e) {
            switch (e.response.status) {
                case 304:
                    return Promise.reject({ result: null, status: 304 });
                default:
                    return Promise.reject(e.response.data);
            }

        }

    }

    async get(url) {

        try {
            const response = await this.client.get(url, { headers: { 'Content-Type': 'application/json' } });

            const result = await response.data;
            if (result.statusCode === 200) {
                return result.data;
            } else {
                throw result;
            }
        } catch (e) {
            return Promise.reject(e.response.data);
        }
    }

    async post(url, data) {
        try {
            const response = await this.client.post(url, data, { headers: { 'Content-Type': 'application/json' } });
            const result = await response?.data;
            if (result.statusCode === 201 || 200) {
                return result.data;
            } else {
                throw result;
            }
        } catch (e) {
            return Promise.reject(e.response?.data)
        }
    }

    async download(url, data) {
        try {
            const response = await this.client.post(url, data, {
                headers: { 'Content-Type': 'application/json;charset=utf-8', 'Accept': 'application/pdf' },
                responseType: 'blob',
            });

            if(response.status === 200) {
                const blob = await response.data;
                const filename = /filename="(.*)"/.exec(response.headers['content-disposition'])[1] || 'filename';
                return {
                    blob,
                    filename
                }
            }
        } catch (e) {
            return Promise.reject(e);
        }
    }


    async delete(url) {
        try {
            const response = await this.client.delete(url, { headers: { 'Content-Type': 'application/json' } });
            const result = await response?.data;
            if (result.statusCode === 201 || 200) {
                return result.data;
            } else {
                throw result;
            }
        } catch (e) {
            return Promise.reject(e.response?.data)
        }
    }

    async put(url, data) {
        try {
            const response = await this.client.put(url, data, { headers: { 'Content-Type': 'application/json' } });
            const result = await response.data;
            if (result.statusCode === 200) {
                return result.data;
            } else {
                throw result;
            }
        } catch (e) {
            return Promise.reject(e.response.data);
        }
    }

    async patch(url, data) {
        try {
            const response = await this.client.patch(url, data, { headers: { 'Content-Type': 'application/json' } });
            const result = await response.data;
            if (result.statusCode === 200 || result.statusCode === 202) {
                return result.data;
            } else {
                throw result;
            }
        } catch (e) {
            return Promise.reject(e.response.data);
        }
    }



}

const authService = new AuthService();
export default authService;


import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import cn from "classnames";
import Tabs from "../Tabs";
import { FilterAps } from '../../../common/Filter';
import ColoringText from './ColoringText';
import ExportReport from "../ExportReport";
import Charts from "./Charts";

const ApplicationsDesktop = ({ analyticsStore, uiStore, userStore }) => {

    const { filter } = analyticsStore;
    const [items, setItems] = useState({ items: [] });
    const [source, setSource] = useState('branches');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        //TODO источник переделать в параметр для получени списка
        if (source === 'branches') {
            analyticsStore.getApsBranchList()
                .then(result => {
                    setItems(result);
                    setLoading(false);
                });
        } else {
            analyticsStore.getApsManagerList()
                .then(result => {
                    setItems(result);
                    setLoading(false);
                });
        }
    }, [JSON.stringify(filter), source]);

    const handleChangeNav = (href) => {
        setSource(href);
    }

    const handlerGetReport = (event) => {
        if (event === 'GET_REPORT') {
            analyticsStore.downloadXlsReport()
                .then(_ => {
                    uiStore.setMessage(
                        {
                            severity: 'success',
                            summary: '',
                            detail: 'Отчет будет отправлен на почту '+ userStore.profile.user.email,
                            sticky: false,
                        }
                    );
                })
                .catch(e => e)
        }
    }

    return (
        <>
            <div className={cn('page__analytics', 'page', 'application-table')}>
                <div className={cn('title')}>
                    <h1>Аналитика</h1>
                    <ExportReport
                        isMobile={false}
                        userStore={userStore}
                        handlerGetReport={handlerGetReport}
                    />
                </div>
                <div className={cn('analytics-tabs')}>
                    <Tabs/>
                </div>
                <div className={cn('analytics-filter')}>
                    <FilterAps
                        loading={loading}
                        openHref={handleChangeNav}
                    />
                </div>

                <Charts />

                <div className={cn('analytics-table', 'application-table', 'table')}>
                    <div className={cn('table__wrapper')}>
                        {loading && <div className={'loader'}/>}
                        <div className={cn("p-datatable", "p-component", "wide")}>
                            <div className="p-datatable-wrapper">
                                <table role="grid">
                                    <thead className="p-datatable-thead">
                                    <tr>
                                        <th>{source === 'branches' ? 'Филиал' : 'ФИО сотрудника'}</th>
                                        <th className={cn('align__right')}>Всего<br/>заявок за<br/>период</th>
                                        <th className={cn('align__right')}>Заявки<br/>в работе</th>
                                        <th className={cn('align__right')}>Заявки<br/>с ошибкой</th>
                                        <th className={cn('align__right')}>Всего<br/>получено<br/>отзывов</th>
                                        <th className={cn('align__right')}>Конверсия</th>
                                        <th className={cn('align__right')}>Получено<br/>позитив.<br/>отзывов</th>
                                        <th className={cn('align__right')}>Перехват<br/>нег.атив<br/>отзывов</th>
                                        <th className={cn('align__right')}>Переходы<br/>на<br/>площадки</th>
                                        <th className={cn('align__right')}>Подтв.<br/>отзывы на<br/>площадках</th>
                                        <th className={cn('align__right')}>NPS</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {items?.total && <FirstRow
                                        data={items?.total}
                                        source={source}
                                    />}

                                    {items?.items?.length > 0 && items.items.map((item, key) => (
                                        <Row
                                            data={item}
                                            key={key + '_' + (Math.random() * 10)}
                                        />
                                    ))}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

/**
 *
 * @param {Object} data
 * @param {string} source
 * @returns {JSX.Element}
 * @constructor
 */
const FirstRow = ({ data, source = 'branch' }) => {

    return (
        <tr className={cn('first-row')}>
            <td>
                <div className={cn('name')}>
                    {source === 'branches' ? <div>Все филиалы</div> : <div>Все сотрудники</div>}
                </div>
            </td>
            <td className={cn('align__right')}>
                <ColoringText value={data.applications.total}/>
            </td>
            <td className={cn('align__right')}>
                <ColoringText value={data.applications.progress}/>
            </td>
            <td className={cn('align__right')}>
                <ColoringText value={data.applications.error}/>
            </td>
            <td className={cn('align__right')}>
                <ColoringText value={data.reviews.total}/>
            </td>
            <td className={cn('align__right')}>
                <ColoringText value={data.rates.conversion} af='%'/>
            </td>
            <td className={cn('align__right')}>
                <ColoringText value={data.reviews.good}/>
            </td>
            <td className={cn('align__right')}>
                <ColoringText value={data.reviews.bad}/>
            </td>
            <td className={cn('align__right')}>
                <ColoringText value={data.reviews.traffic}/>
            </td>
            <td className={cn('align__right')}>
                <ColoringText value={data.extreviews.other}/>
            </td>
            <td className={cn('align__right')}>
                <ColoringText value={data.rates.nps}/>
            </td>
        </tr>
    );
};

/**
 *
 * @param {Object} data
 * @returns {JSX.Element}
 * @constructor
 */
const Row = ({ data }) => {

    const [title, title2] = data.branch?.title ? data.branch.title.split(' - ') : [null, null];

    return (
        <tr>
            <td>
                <div className={cn('name')}>
                    <div className={cn(
                        'ico',
                        { 'ico__bag': data.branch },
                        { 'ico__user': data.manager }
                    )}
                    />
                    <div title={data.branch?.title || data.manager?.name}>
                        {title ?
                            <span>{title} {!!title2 > 0 && <>-<br/>{title2}</>}</span>
                            :
                            data.manager?.name}
                    </div>
                </div>
            </td>
            <td className={cn('align__right')}>
                <ColoringText value={data.applications.total}/>
            </td>
            <td className={cn('align__right')}>
                <ColoringText value={data.applications.progress}/>
            </td>
            <td className={cn('align__right')}>
                <ColoringText value={data.applications.error}/>
            </td>
            <td className={cn('align__right')}>
                <ColoringText value={data.reviews.total}/>
            </td>
            <td className={cn('align__right')}>
                <ColoringText value={data.rates.conversion} af='%'/>
            </td>
            <td className={cn('align__right')}>
                <ColoringText value={data.reviews.good}/>
            </td>
            <td className={cn('align__right')}>
                <ColoringText value={data.reviews.bad}/>
            </td>
            <td className={cn('align__right')}>
                <ColoringText value={data.reviews.traffic}/>
            </td>
            <td className={cn('align__right')}>
                <ColoringText value={data.extreviews.other}/>
            </td>
            <td className={cn('align__right')}>
                <ColoringText value={data.rates.nps}/>
            </td>
        </tr>

    );
}


export default inject('analyticsStore', 'uiStore', 'userStore')(observer(ApplicationsDesktop));

import React, { useEffect, useState } from "react";
import okAnalyticsService from "../../../../services/okAnalytics";
import { isMobile } from "react-device-detect";
import cn from "classnames";
import Tabs from "../Tabs";
import { FilterQr } from "../../../common/Filter";
import { inject, observer } from "mobx-react";
import ColoringText from "../applications/ColoringText";
import { AD_PLATFORM } from "../../../../models/ad-platform";
import ExportReport from "../ExportReport";
import "./QRCode.scss"

const QRCodeCommon = () => {
    useEffect(() => {
        okAnalyticsService.addEntity({
            page: 'analytics_qrcode',
            target: 'page',
            event: 'open',
        });
    }, []);

    const getClientWidth = () => {
        return document.documentElement.clientWidth;
    }

    return (
        <>
            {(isMobile && getClientWidth() <= 1024) ? <QRCodeDesktop/> : <QRCodeDesktop/>}
        </>
    );

}
const QRCodeDesktop = inject('analyticsStore', 'userStore', 'uiStore')(observer(
    ({ analyticsStore, userStore, uiStore }) => {
        const [items, setItems] = useState([]);
        const [loading, setLoading] = useState(false);
        const [total, setTotal] = useState([]);

        const { filterQr } = analyticsStore;

        useEffect(() => {
            setLoading(true);
            analyticsStore.getQrList()
                .then(result => {
                    if(result.total?.transitions) {
                        setTotal(result.total);
                    }
                    setItems(result.items);
                    setLoading(false);
                });
        }, [JSON.stringify(filterQr)]);

        const handlerGetReport = (event) => {
            if (event === 'GET_REPORT') {
                analyticsStore.downloadXlsReportQr()
                    .then(_ => {
                        uiStore.setMessage(
                            {
                                severity: 'success',
                                summary: '',
                                detail: 'Отчет будет отправлен на почту '+ userStore.profile.user.email,
                                sticky: false,
                            }
                        );
                    })
                    .catch(e => e)
            }
        }

        return (
            <>
                <div className={cn('page__analytics', 'page', 'qrcode-table')}>
                    <div className={cn('title')}>
                        <h1>Аналитика</h1>
                        <ExportReport
                            isMobile={false}
                            userStore={userStore}
                            handlerGetReport={handlerGetReport}
                        />

                    </div>
                    <div className={cn('analytics-tabs')}>
                        <Tabs/>
                    </div>
                    <div className={cn('analytics-filter')}>
                        <FilterQr loading={loading}/>
                    </div>

                    <div className={cn('analytics-table', 'table', 'qrcode-table')}>
                        <div className={cn('table__wrapper')}>
                            {loading && <div className={'loader'}/>}
                            <div className={cn('table')}>
                                <div className={cn('table__header')}>
                                    <div className={cn('table__row')} style={{ "--my-repeat": total.transitions?.length+2 }}>
                                        <div className={cn('table__cell', 'cell__title')}>Филиал</div>
                                        <div className={cn('table__cell')}>Всего переходов</div>

                                        {total.transitions?.length > 0 && total.transitions.map((item, key) => {
                                            const {ico, name, shortName} = AD_PLATFORM.find(ad => ad.value === item.code)
                                            return (
                                                <div className={cn('table__cell')} key={key}>
                                                    <div className={cn('ad__ico')}>
                                                        <img src={ico} alt={name} width={21} height={21}/>
                                                        <div>{shortName}</div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                        <div className={cn('table__cell')}>Перехвачено нег. отзывов</div>
                                    </div>
                                </div>

                                <TotalLine data={total}/>

                                <div className={cn('table__body')}>
                                    {items.length > 0 && items.map((item, key) => {
                                        return (
                                            <ItemLine key={key}
                                                      data={item}
                                                      dataTotal={total}/>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
))

const ItemLine = ({ data, dataTotal }) => {

    const [total, setTotal] = useState(0);

    useEffect(() => {
        let countTotal = 0;
        data.transitions.map(item => {
            countTotal = countTotal + item.value;
        });
        setTotal(countTotal)
    }, [data])


    const [title, title2] = data.branch.title.split(' - ');

    return (
        <>
            <div className={cn('table__row')} style={{ "--my-repeat": dataTotal.transitions?.length+2 }}>
                <div className={cn('table__cell', 'cell', 'cell__title')}>
                    <div className={cn('ico', 'ico__bag')}></div>
                    <div title={data.branch.title}>
                        {title} {!!title2 > 0 && <>-<br/>{title2}</>}
                    </div>
                </div>
                <div className={cn('table__cell')}>
                    <ColoringText value={total}/>
                </div>

                {dataTotal.transitions?.length > 0 &&dataTotal.transitions.map((i, key) => {
                    const value = data.transitions.find(v => v.code === i.code)
                    return (
                        <div className={cn('table__cell')} key={key}>
                            {value ? <ColoringText value={value.value}/> : <ColoringText value={0}/>}
                        </div>
                    )
                })}
                <div className={cn('table__cell')}>
                    <ColoringText value={data.claims}/>
                </div>
            </div>
        </>
    )
}


const TotalLine = ({ data }) => {
    const [total, setTotal] = useState(0);
    const [platform, setPlatform] = useState({});

    useEffect(() => {
        let tmpPlatform = {};
        let countTotal = 0;

        if (data.transitions?.length > 0) {
            data.transitions.map(item => {
                countTotal = countTotal + item.value;
                tmpPlatform[item.code] = item.value;
            });
        }

        setTotal(countTotal)
        setPlatform(tmpPlatform)
    }, [data])

    return (
        <div className={cn('table__row', 'row')} style={{ "--my-repeat": data.transitions?.length+2 }}>
            <div className={cn('table__cell', 'cell', 'cell__title')}>
                <div className={cn('ico', 'ico__bag')} />
                <div>Все филиалы</div>
            </div>
            <div className={cn('table__cell')}>
                <ColoringText value={total}/>
            </div>

            {data.transitions?.length > 0 && data.transitions.map((source, key) => {
                return (
                    <div className={cn('table__cell')} key={key}>
                        <ColoringText value={platform[source.code]}/>
                    </div>
                )
            })}
            <div className={cn('table__cell')}>
                <ColoringText value={data.claims}/>
            </div>

        </div>
    )
}

export default QRCodeCommon;

import React, { useEffect, useState } from "react";
import cn from "classnames";
import Tabs from "../Tabs";
import { FilterTraffic } from "../../../common/Filter";
import { ChartTraffic } from "./charts/ChartTraffic";
import { inject, observer } from "mobx-react";

//TODO +порядок с логотипами источников
import { AD_PLATFORM_TYPE } from "../../../../models/ad-platform";
import yandex from '../../../../images/platforms_icon_final/01.png';
import toGis from '../../../../images/platforms_icon_final/02.png';
import google from '../../../../images/platforms_icon_final/08.png';

import AdditionalChartTraffic from "./charts/AdditionalChartTraffic";
import ExportReport from "../ExportReport";

const TrafficDesktop = inject('analyticsStore', 'uiStore', 'userStore')(observer(
    ({ analyticsStore, uiStore, userStore }) => {

        const [loading, setLoading] = useState(true);
        const [chartData, setChartData] = useState(null);
        const [additionalChartData, setAdditionalChartData] = useState(null);
        const { filterTraffic, setFieldValue, sources } = analyticsStore;
        const [chartSource, setChartSource] = useState(null);

        useEffect(() => {
            analyticsStore.getSources();
        }, []);

        useEffect(() => {
            setChartSource(
                sources.find(item => (item.value === filterTraffic.sources[0].value))
            );
        }, [sources]);

        useEffect(() => {
            analyticsStore.getAdditionalTraffic()
                .then(result => {
                    setAdditionalChartData(result)
                });

            analyticsStore.getTraffic()
                .then(result => {
                    setChartData(result);
                    setLoading(false);
                });
        }, [JSON.stringify(filterTraffic)]);

        const getPeriod = () => filterTraffic.groupby.map(item => item.value);

        const onSelectSource = (source) => {
            setChartSource(source);
            setFieldValue('sources', [{ value: source.value }], 'filterTraffic');
        }

        const handlerGetReport = (event) => {
            if (event === 'GET_REPORT') {
                analyticsStore.downloadXlsReportTraffic()
                    .then(_ => {
                        uiStore.setMessage(
                            {
                                severity: 'success',
                                summary: '',
                                detail: 'Отчет будет отправлен на почту '+ userStore.profile.user.email,
                                sticky: false,
                            }
                        );
                    })
                    .catch(e => e)
            }
        }

        return (
            <>
                <div className={cn('page__analytics', 'page')}>
                    <div className={cn('title')}>
                        <h1>Аналитика</h1>
                        <ExportReport
                            isMobile={false}
                            userStore={userStore}
                            handlerGetReport={handlerGetReport}
                        />
                    </div>
                    <div className={cn('analytics-tabs')}>
                        <Tabs />
                    </div>
                    <div className={cn('analytics-filter')}>
                        <FilterTraffic loading={loading} />

                        {sources.length > 0 && chartData && <div className={cn('analytics-footer')}>
                            <SourcesFooter
                                onSelectSource={onSelectSource}
                                sources={sources}
                                selected={filterTraffic.sources[0].value}
                            />
                        </div>}
                    </div>
                    {loading && <div className={'loader'} />}

                    {chartData && <div className={cn('analytics-table', 'table')}>
                        <div className={cn('table__wrapper')}>
                            <ChartTraffic
                                source={chartSource}
                                data={chartData}
                                period={getPeriod()}
                            />
                        </div>
                    </div>}

                    {additionalChartData && <div className={cn('analytics-table', 'table')}>
                        <AdditionalChartTraffic
                            source={chartSource}
                            data={additionalChartData}
                            period={getPeriod()}
                        />
                    </div>}
                </div>
            </>
        );
    }
));

/**
 *
 * @type {[{ico: *, name: string, value: (AD_PLATFORM_TYPE|AdPlatformType)},{ico: *, name: string, value: (AD_PLATFORM_TYPE|AdPlatformType)}]}
 */
const sourcesModel = [
    { value: AD_PLATFORM_TYPE.YANDEX, name: 'Yandex', ico: yandex },
    { value: AD_PLATFORM_TYPE.DGIS, name: '2Gis', ico: toGis },
    { value: AD_PLATFORM_TYPE.GOOGLE, name: 'Google Maps', ico: google },
];

const SourcesFooter = ({ onSelectSource, sources, selected = null }) => {
    return (
        <div className={cn('analytics-carousel')}>
            <ul>
                {
                    sources.map((item, key) => {
                        const show = sourcesModel.find(val => val.value === item.code)
                        if(show) {
                            return (
                                <li key={key} onClick={() => onSelectSource(item)}>
                                    <img src={show.ico} alt="" width={40} height={40} title={show.name}
                                         className={cn({ 'active': (item.value === selected) })}
                                    />
                                </li>
                            );
                        }
                    })
                }
            </ul>
        </div>
    );
}

export default TrafficDesktop;

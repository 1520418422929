import React, { useEffect, useState } from "react";
import cn from "classnames";
import Tabs from "../Tabs";
import { FilterApsBranch } from "../../../common/Filter";
import PrepareNumber from "../../../common/PrepareNumber";
import { inject, observer } from "mobx-react";
import { getDetailDataByPlatform } from "../../../../helpers/index";
import { useParams } from "react-router";
import { ToolTip } from "../../../common/ToolTip";
import DiffPercentString from "../DiffPercentString";
import DiffAwgTimeReply from "../DiffAwgTimeReply";
import ColoringText from "../applications/ColoringText";


const BranchDesktop = ({ analyticsStore }) => {
    const { filterBranch } = analyticsStore;
    const [reviews, setReviews] = useState({ items: [] });
    const [appls, setAppls] = useState({ items: [] });
    const [loading, setLoading] = useState(true);

    let { id } = useParams();
    useEffect(() => {
        id && analyticsStore.setFieldValue('branches', [{ value: Number(id) }], 'filterBranch');
    }, [])

    useEffect(() => {
        setLoading(true);

        Promise.all([
            analyticsStore.getReviewsByPlatformList(),
            analyticsStore.getApsManagerByPlatformList(),
        ]).then(([reviews, appls]) => {
            setReviews(reviews);
            setAppls(appls);
            setLoading(false);
        });
    }, [JSON.stringify(filterBranch)]);

    return (
        <>
            <div className={cn('page__analytics', 'page', 'branch-table')}>
                <div className={cn('title')}>
                    <h1>Аналитика</h1>
                </div>
                <div className={cn('analytics-tabs')}>
                    <Tabs/>
                </div>
                <div className={cn('analytics-filter')}>
                    <FilterApsBranch loading={loading}/>
                </div>
                <div className={cn('analytics-table', 'table')}>
                    <div className={cn('analytics-table__header')}>
                        <h3>Отзывы</h3>
                    </div>

                    <div className={cn('table__wrapper', 'branch')}>
                        {loading && <div className={'loader'}/>}
                        <div className="p-datatable p-component">
                            <div className="p-datatable-wrapper">
                                <table role="grid">
                                    <thead className="p-datatable-thead">
                                    <tr>
                                        <th>Источник</th>
                                        <th className={cn('rating')}>
                                            <div className={cn('answer__tooltip', 'align-right')}>
                                                Рейтинг
                                                <div className={'tooltip__wrapper'}>
                                                    <ToolTip mobile={false}>
                                                        В скобках указан рейтинг<br/> за выбранный период по отзывам
                                                    </ToolTip>
                                                </div>
                                            </div>
                                        </th>
                                        <th className={cn('scores', 'align__right')}>Оценки</th>
                                        <th className={cn('align__left')}>&nbsp;</th>
                                        <th className={cn('review', 'align__right')}>Отзывы</th>
                                        <th className={cn('align__left')}>&nbsp;</th>
                                        <th className={cn('align__right', 'answer')}>
                                            <div className={'answer__tooltip'}>
                                                С ответом
                                                <div className={'tooltip__wrapper'}>
                                                    <ToolTip mobile={false}>
                                                        Доля ответов на отзывы:<br/><b>% итого (% за выбранный
                                                        период)</b>
                                                    </ToolTip>
                                                </div>
                                            </div>
                                        </th>
                                        <th className={cn('align__right', 'time')}>
                                            <div className={'answer__tooltip'}>
                                                Время ответа
                                                <div className={'tooltip__wrapper'}>
                                                    <ToolTip mobile={false}>
                                                        Среднее время ответа на отзывы:<br/><b>за последние 90 дней (за выбранный
                                                        период)</b>
                                                    </ToolTip>
                                                </div>
                                            </div>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {reviews?.total && <BranchFirstRow data={reviews?.total}/>}
                                    {reviews.items.length > 0 && reviews.items.map((item, key) => (
                                        <BranchRow
                                            data={item}
                                            key={key + '_' + item.id}
                                        />
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={cn('analytics-table', 'application-table', 'table')}>
                    <div className={cn('analytics-table__header')}>
                        <h3>Заявки</h3>
                    </div>
                    <div className={cn('table__wrapper', 'branch')}>
                        {loading && <div className={'loader'}/>}
                        <div className={cn("p-datatable", "p-component", "wide")}>
                            <div className="p-datatable-wrapper">
                                <table role="grid">
                                    <thead className="p-datatable-thead">
                                    <tr>
                                        <th>Сотрудник</th>
                                        <th className={cn('align__right')}>Всего<br />заявок за<br />период</th>
                                        <th className={cn('align__right')}>Заявки<br />в работе</th>
                                        <th className={cn('align__right')}>Заявки<br />с ошибкой</th>
                                        <th className={cn('align__right')}>Всего<br />получено<br />отзывов</th>
                                        <th className={cn('align__right')}>Конверсия</th>
                                        <th className={cn('align__right')}>Получено<br />позитив.<br/>отзывов</th>
                                        <th className={cn('align__right')}>Перехват<br />нег.атив<br />отзывов</th>
                                        <th className={cn('align__right')}>Переходы<br />на<br />площадки</th>
                                        <th className={cn('align__right')}>Подтв.<br />отзывы на<br />площадках</th>
                                        <th className={cn('align__right')}>NPS</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {appls?.total && <ApplFirstRow data={appls?.total}/>}
                                    {appls.items.length > 0 && appls.items.map((item, key) => (
                                        <ApplRow
                                            data={item}
                                            key={key + '_' + item.id}
                                        />
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

/**
 *
 * @param {Object} data
 * @returns {JSX.Element}
 * @constructor
 */
const BranchFirstRow = ({ data }) => {
    return (
        <tr className={cn('first-row')}>
            <td>
                <div className={cn('name')}>
                    <div>Все источники</div>
                </div>
            </td>
            <td>
                <span className={cn('rating')}>
                    <span className={cn('pi-star pi')}/>
                    <span className={cn('value')}>
                        {Number(data.close.rating.toFixed(1)).toLocaleString('ru-RU')}
                    </span>
                    <PrepareNumber value={data.diff.rating}/>
                    {data.diff.reviews ?
                        <span>&nbsp;({Number((data.diff.weightedReview/data.diff.reviews).toFixed(1)).toLocaleString('ru-RU')})</span>
                        :
                        <span>&nbsp;(0)</span>}
                </span>
            </td>
            <td className={cn('align__right')}>
                {Number(`${data.close.scores}`).toLocaleString('ru-RU')}
            </td>
            <td>
                <PrepareNumber value={data.diff.scores}/>
            </td>
            <td className={cn('align__right')}>
                {Number(`${data.close.reviews}`).toLocaleString('ru-RU')}
            </td>
            <td>
                <PrepareNumber value={data.diff.reviews}/>
            </td>
            <td className={cn('align__right')}>
                <DiffPercentString data={data}/>
            </td>
            <td className={cn('align__right')}>
                <DiffAwgTimeReply data={data}/>
            </td>
        </tr>
    );
};

/**
 *
 * @param {Object} data
 * @returns {JSX.Element}
 * @constructor
 */
const BranchRow = ({ data }) => {

    const { name, ico } = getDetailDataByPlatform(data.adplatform.code)
    return (
        <tr>
            <td>
                <div className={cn('name')}>
                    <a href={data.adplatform.url} target="_blank">
                        <img src={ico} alt="" width={20} height={20} title={data.adplatform.title}/>
                    </a>
                    <div>{name}</div>
                </div>
            </td>
            <td>
                <span className={cn('rating')}>
                    <span className={cn('pi-star pi')}/>
                    <span className={cn('value')}>
                        {Number(data.close.rating.toFixed(1)).toLocaleString('ru-RU')}
                    </span>
                    <PrepareNumber value={data.diff.rating}/>

                    {data.diff.reviews ?
                        <span className={cn('text-color--gray')}>&nbsp;({Number((data.diff.weightedReview/data.diff.reviews).toFixed(1)).toLocaleString('ru-RU')})</span>
                        :
                        <span className={cn('text-color--gray')}>&nbsp;(0)</span>}
                </span>
            </td>
            <td className={cn('align__right')}>
                {Number(`${data.close.scores}`).toLocaleString('ru-RU')}
            </td>
            <td>
                <PrepareNumber value={data.diff.scores}/>
            </td>
            <td className={cn('align__right')}>
                {Number(`${data.close.reviews}`).toLocaleString('ru-RU')}
            </td>
            <td>
                <PrepareNumber value={data.diff.reviews}/>
            </td>
            <td className={cn('align__right')}>
                <DiffPercentString data={data}/>
            </td>
            <td className={cn('align__right')}>
                <DiffAwgTimeReply data={data}/>
            </td>
        </tr>
    );
};

/**
 *
 * @param {Object} data
 * @returns {JSX.Element}
 * @constructor
 */
const ApplFirstRow = ({ data }) => {
    return (
        <tr className={cn('first-row')}>
            <td>
                <div className={cn('name')}>
                    <div>Все сотрудники</div>
                </div>
            </td>
            <td className={cn('align__right')}>
                <ColoringText value={data.applications.total}/>
            </td>
            <td className={cn('align__right')}>
                <ColoringText value={data.applications.progress}/>
            </td>
            <td className={cn('align__right')}>
                <ColoringText value={data.applications.error}/>
            </td>
            <td className={cn('align__right')}>
                <ColoringText value={data.reviews.total}/>
            </td>
            <td className={cn('align__right')}>
                <ColoringText value={data.rates.conversion} af='%'/>
            </td>
            <td className={cn('align__right')}>
                <ColoringText value={data.reviews.good}/>
            </td>
            <td className={cn('align__right')}>
                <ColoringText value={data.reviews.bad}/>
            </td>
            <td className={cn('align__right')}>
                <ColoringText value={data.reviews.traffic}/>
            </td>
            <td className={cn('align__right')}>
                <ColoringText value={data.extreviews.other}/>
            </td>
            <td className={cn('align__right')}>
                <ColoringText value={data.rates.nps}/>
            </td>
        </tr>
    );
}

/**
 *
 * @param {Object} data
 * @returns {JSX.Element}
 * @constructor
 */
const ApplRow = ({ data }) => {

    return (
        <>
            <tr>
                <td>
                    <div className={cn('name')}>
                        <div className={cn(
                            'ico',
                            { 'ico__bag': data.branch },
                            { 'ico__user': data.manager }
                        )}
                        />
                        <div
                            title={data.branch?.title || data.manager?.name}>{data.branch?.title || data.manager?.name}</div>
                    </div>
                </td>
                <td className={cn('align__right')}>
                    <ColoringText value={data.applications.total}/>
                </td>
                <td className={cn('align__right')}>
                    <ColoringText value={data.applications.progress}/>
                </td>
                <td className={cn('align__right')}>
                    <ColoringText value={data.applications.error}/>
                </td>
                <td className={cn('align__right')}>
                    <ColoringText value={data.reviews.total}/>
                </td>
                <td className={cn('align__right')}>
                    <ColoringText value={data.rates.conversion} af='%'/>
                </td>
                <td className={cn('align__right')}>
                    <ColoringText value={data.reviews.good}/>
                </td>
                <td className={cn('align__right')}>
                    <ColoringText value={data.reviews.bad}/>
                </td>
                <td className={cn('align__right')}>
                    <ColoringText value={data.reviews.traffic}/>
                </td>
                <td className={cn('align__right')}>
                    <ColoringText value={data.extreviews.other}/>
                </td>
                <td className={cn('align__right')}>
                    <ColoringText value={data.rates.nps}/>
                </td>
            </tr>
        </>
    );
};


export default inject('analyticsStore')(observer(BranchDesktop));

import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { Link, useLocation } from "react-router-dom";
import cn from 'classnames';
import { Button } from "primereact/button";
import HeaderLogo from './../images/header_logo.png';
import MobileMenu from '../components/common/MobileMenu';
import Profile from "../components/common/Profile";
import NotificationMark from "../components/common/NotificationMark";
import './Header.scss';

import { isMobile } from "react-device-detect";
import OkMenu from "./common/OkMenu/OkMenu";


const Header = inject('userStore')(observer(({ userStore }) => {
    const { logout, isAuthorized, profile } = userStore;

    const onLogout = () => {
        logout().then(() => {
            document.location.replace('/');
        });
    }

    return (
        <div className={'main-header'}>
            <div className={'main-header__wrapper'}>
                <div className={cn('main-header__logo')}>
                    <Link to='/' data-tag='index'><img src={HeaderLogo} alt="okreview" width={157} height={37}/></Link>
                </div>
                {isAuthorized && <TopMenu/>}
                {/*{isAuthorized && <MenuOld allowed={profile.allowed} />}*/}
                <UserInfo
                    handleOnClick={onLogout}
                />
                {isAuthorized && <MobileMenu/>}
            </div>
        </div>
    );

}));

const TopMenu = () =>{
    const menu = [
        {
            label: 'Дашборд',
            link: '/dashboard',
            key: 'dashboard',
            access: 'allow'
        },
        {
            label: 'Сбор отзывов',
            key: 'SubMenu1',
            access: 'allow',
            children: [
                {
                    label: 'Заявки',
                    link: '/application/list',
                    key: 'application_list',
                    access: 'listApplications'
                },
                {
                    label: 'QR-коды',
                    link: '/qr/list',
                    key: 'qr_list',
                    access: 'allow'
                },
                {
                    label: 'Создать заявку',
                    link: '/application/add',
                    key: 'application_add',
                    access: 'createApplication'
                },
            ]
        },
        {
            label: 'Мониторинг',
            link: '/monitoring/list',
            key: 'monitoring_list',
            access: 'listReviews'
        },

        {
            label: 'Аналитика',
            link: '/analytics/rating',
            key: 'analytics_applications',
            access: 'analytics'
        },
/*
        {
            label: 'Геоданные',
            link: '/company-list',
            key: 'analytics_applications',
            access: 'manageGeodata'
        },
*/
    ]

    const links = {
        'dashboard': '/dashboard',
        'application_list': '/application/list',
        'qr_list': '/qr/list',
        'application_add': '/application/add',
        'monitoring_list': '/monitoring/list',
        'analytics_applications': '/analytics/applications',
    }

    const [current, setCurrent] = useState('dashboard');

    useEffect(() => {
        Object.keys(links).map(key => {
            if (links[key] === location.pathname) {
                setCurrent(key)
            }
        })
    }, []);

    return (
        <>
            <OkMenu menuModel={menu} selectedKey={current}/>
        </>
    );
}

const NO_SHOW_LOGIN_BTN_PATHNAME = [
    '/login',
    '/',
];
const UserInfo = inject("userStore")(observer(
    ({ userStore, handleOnClick }) => {
        const location = useLocation();
        const { isAuthorized } = userStore;
        return (
            <React.Fragment>
                {(!isAuthorized && !NO_SHOW_LOGIN_BTN_PATHNAME.includes(location.pathname)) &&
                    <div className={cn('main-header__btn', 'btn-login')}>
                        <Link to='/login' data-tag='login'><Button label='Войти' className={cn('btn-outlined')}/></Link>
                    </div>}
                {(isAuthorized) &&
                    <>
                        {!isMobile && <NotificationMark/>}
                        <div className={cn('profile-container')}>
                            <Profile
                                handleOnClick={handleOnClick}
                            />
                        </div>
                    </>
                }
            </React.Fragment>
        );
    }
));

export default Header;

import React, {useEffect, useRef} from "react";
import cn from "classnames";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5locales_ru_RU from "@amcharts/amcharts5/locales/ru_RU";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

const Chart2 = ({data}) => {

    let root = null;
    let container = useRef(null);

    const destroyChart = () => {
        root && root.dispose();
    }

    const initChart = (data = [], totalHeight = 1200, settings = []) => {
        am5.ready(function () {

            root = am5.Root.new("chart2");
            root.locale = am5locales_ru_RU;

            root.setThemes([
                am5themes_Animated.new(root)
            ]);

            let chart = root.container.children.push(am5xy.XYChart.new(root, {
                panX: false,
                panY: false,
                //paddingLeft: 10,
                //paddingRight: 10,
                //paddingBottom: -4,
                layout: root.verticalLayout
            }));

            chart.zoomOutButton.set("forceHidden", true);

            let xRenderer = am5xy.AxisRendererX.new(root, {
                minGridDistance: 30,
                minorGridEnabled: true,
                strokeOpacity: 0.1
            });

            xRenderer.labels.template.setAll({
                paddingTop: 5,
                visible: true,
                fontSize: "11px"
            });

            xRenderer.grid.template.setAll({
                visible: false,
                location: 1
            })

            let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
                categoryField: "month",
                renderer: xRenderer,
            }));

            xAxis.data.setAll(data);

            let yRenderer = am5xy.AxisRendererY.new(root, {
                strokeOpacity: 0
            })

            yRenderer.labels.template.setAll({
                visible: true,
                fontSize:11
            });

            yRenderer.grid.template.setAll({
                visible: true,
                minorGridEnabled: false,
            })

            let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
                calculateTotals: true,
                renderer: yRenderer,
            }));

            let legend = chart.children.push(am5.Legend.new(root, {
                centerX: am5.percent(50),
                x: am5.percent(50),
            }));

            legend.valueLabels.template.set("forceHidden", true);

            legend.markers.template.setAll({
                height:10,
                width:10,
            })

            legend.labels.template.setAll({
                fontSize:11,
                paddingRight:0
            })

            function makeSeries(name, fieldName, color, data, totals = false) {
                let series = chart.series.push(am5xy.ColumnSeries.new(root, {
                    name: name,
                    stacked: true,
                    xAxis: xAxis,
                    yAxis: yAxis,
                    valueYField: fieldName,
                    //valueYShow: "valueYTotalPercent",
                    categoryXField: "month",
                    paddingTop:15,
                }));



                if (!totals) {
                    series.bullets.push(function () {

                        let label = am5.Label.new(root, {
                            text: "{valueY}",
                            fontSize: '11px',
                            fill: '#373947',
                            centerY: am5.p50,
                            centerX: am5.p50,
                            populateText: true,
                            paddingTop:40,
                        })

                        label.adapters.add("text", function(text, target) {
                            const valueY = target.dataItem.get('valueY')
                            return valueY !== 0 ? valueY : '';
                        });

                        return am5.Bullet.new(root, {
                            sprite: label
                        });
                    });
                    legend.data.push(series);
                } else {
                    series.bullets.push(function () {
                        return am5.Bullet.new(root, {
                            sprite: am5.Label.new(root, {
                                text: "{valueYTotal}",
                                fontSize: '11px',
                                fill: '#373947',
                                centerY: 10,
                                centerX: am5.p50,
                                populateText: true
                            })
                        });
                    });
                }


                series.columns.template.setAll({
                    //width: 50,
                    maxWidth:45,
                    fill: color,
                    stroke: color,
                });

                series.data.setAll(data);
                series.appear();
                container.current = root;
            }

            settings.map((item, _) => {
                makeSeries(item.title.toString(), item.code.toString(), item.color.toString(), data);
            })
            makeSeries("none", "none", 'transparent', data, true);
            chart.appear(1000, 100);
        })

    }

    useEffect(() => {
        initChart(data.data, (data.total*1.1), data.legend)

        return () => destroyChart();
    }, [data])


    return (
        <>
            <div className={cn('chart')} style={{minHeight: "350px"}}>
                <div className={cn('chart__title')}>Распределение заявок по статусам</div>
                <div className={cn('chart__content')} id={'chart2'}></div>
                <div className={cn('chart__legend')}>&nbsp;</div>
            </div>

        </>
    )
}

export default Chart2;

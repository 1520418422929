import React, { useEffect, useRef, useState } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4lang_ru_RU from "@amcharts/amcharts4/lang/ru_RU";
import { pluralize } from '../../../../../helpers';

import "./ChartTraffic.scss";
import cn from "classnames";
import moment from "moment";

const PERIOD_LIST = {
    day: 'день',
    week: 'неделя',
    month: 'месяц',
    year: 'год',
}


const ChartTraffic = ({ source = null, data = {}, period = '' }) => {
    am4core.useTheme(am4themes_animated);
    const chart = useRef(null);
    const [summary, setSummary] = useState(0);
    let x = null;

    const parseDynamics = (result) => {
        const tmp = {
            'total': Number(result.summary.views),
        }

        const data = result.series.map((item) => {
            return {
                'startDate': moment(item.startDate).format(),
                'endDate': moment(item.endDate).format(),
                'value': item.views,
            }
        })
        return {
            summary: tmp,
            data: data
        }
    }

    const initChart = (data = []) => {
        x = am4core.create("chart-source", am4charts.XYChart);
        x.language.locale = am4lang_ru_RU;
        x.padding(10, 20, 5, 10);
        x.numberFormatter.numberFormat = "#.a";
        let dateAxis = x.xAxes.push(new am4charts.DateAxis());

        dateAxis.dateFormats.setKey("day", "dd.MM");
        dateAxis.periodChangeDateFormats.setKey("day", "dd.MM");

        dateAxis.dateFormats.setKey("week", "dd.MM");
        dateAxis.periodChangeDateFormats.setKey("week", "dd.MM");

        dateAxis.dateFormats.setKey("month", "MM.YYYY");
        dateAxis.periodChangeDateFormats.setKey("month", "MM.YYYY");

        dateAxis.dateFormats.setKey("year", "YYYY");
        dateAxis.periodChangeDateFormats.setKey("year", "YYYY");

        let valueAxis = x.yAxes.push(new am4charts.ValueAxis());

        valueAxis.renderer.labels.template.disabled = false;
        valueAxis.renderer.grid.template.disabled = true;

// Create series
        let series = x.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueY = "value";
        series.dataFields.dateX = "startDate";
        series.name = "value";

        let columnTemplate = series.columns.template;
        columnTemplate.tooltip = new am4core.Tooltip();
        columnTemplate.tooltip.label.fontSize = 12;

        columnTemplate.tooltip.pointerOrientation = 'left';
        columnTemplate.tooltip.getFillFromObject = false;
        columnTemplate.tooltip.label.fill = am4core.color("#7A7A7A")

        columnTemplate.tooltipX = am4core.percent(50);
        columnTemplate.tooltipY = am4core.percent(0);

        columnTemplate.tooltipText = "{tooltip}{startDate.formatDate('dd.MM.YYYY')}\nКол-во: {value}"
        columnTemplate.fillOpacity = 1;
        columnTemplate.strokeWidth = 0;
        columnTemplate.strokeOpacity = 1;
        columnTemplate.fill = am4core.color("#0FAFE2");

        let fillModifier = new am4core.LinearGradientModifier();
        fillModifier.opacities = [1, 0.5];
        fillModifier.offsets = [0, 1];
        fillModifier.gradient.rotation = 90;
        columnTemplate.fillModifier = fillModifier;

        x.data = data;
        chart.current = x;
    }

    const destroyChart = () => {
        x && x.dispose();
    }

    useEffect(() => {
        const res = parseDynamics(data);
        setSummary(res.summary.total);

        if(summary > 0) {
            initChart(res.data);
        }

        return () => destroyChart();

    }, [data, summary]);

    return (
        <>
            <div className={cn('chart')}>
                <div className={cn('chart__header')}>
                    <div className={cn('header__title')}>
                        <div className={cn('position__left')}>
                            <h3>{source?.name}</h3>
                            {summary > 0 &&
                                <>
                                    <span className={cn('separator')}>&nbsp;|&nbsp;</span>
                                    <span className={cn('count')}>{summary.toLocaleString('ru-RU')}</span>
                                    <span className={cn('sub_text')}>
                                        {pluralize(summary, ['просмотр', 'просмотра', 'просмотров'], false)}
                                    </span>
                                </>
                            }
                        </div>
                        <span className={cn('sub_text', 'position__right')}>Период: {PERIOD_LIST[period]}</span>
                    </div>
                </div>
                {summary > 0 ? <div id='chart-source' className={cn('chart__body')}  style={{ width: '100%', height: '300px' }} />
                    :
                    <div  className={cn('chart__body')} style={{height: '100px'}}>Нет подключенных источников</div>}
            </div>
        </>
    );
};

const MobileChartTraffic = ({ source= null, data = {}, period = '' }) => {
    am4core.useTheme(am4themes_animated);
    const chart = useRef(null);
    let x = null;

    const parseDynamics = (result) => {
        const tmp = {
            'total': Number(result.summary.views),
        }

        const data = result.series.map((item) => {
            return {
                'startDate': moment(item.startDate).format(),
                'endDate': moment(item.endDate).format(),
                'value': item.views,
            }
        })

        return {
            summary: tmp,
            data: data
        }
    }

    const initChart = (data = []) => {
        x = am4core.create("chart-source", am4charts.XYChart);
        x.language.locale = am4lang_ru_RU;
        //x.padding(10, 20, 5, 10);
        x.numberFormatter.numberFormat = "#.a";

        let dateAxis = x.xAxes.push(new am4charts.DateAxis());

        dateAxis.dateFormats.setKey("day", "dd.MM");
        dateAxis.periodChangeDateFormats.setKey("day", "dd.MM");

        dateAxis.dateFormats.setKey("week", "dd.MM");
        dateAxis.periodChangeDateFormats.setKey("week", "dd.MM");

        dateAxis.dateFormats.setKey("month", "MM.YYYY");
        dateAxis.periodChangeDateFormats.setKey("month", "MM.YYYY");

        dateAxis.dateFormats.setKey("year", "YYYY");
        dateAxis.periodChangeDateFormats.setKey("year", "YYYY");

        let valueAxis = x.yAxes.push(new am4charts.ValueAxis());

        valueAxis.renderer.labels.template.disabled = false;
        valueAxis.renderer.grid.template.disabled = true;

// Create series
        let series = x.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueY = "value";
        series.dataFields.dateX = "startDate";
        series.name = "value";

        let columnTemplate = series.columns.template;
        columnTemplate.tooltip = new am4core.Tooltip();
        columnTemplate.tooltip.label.fontSize = 12;

        columnTemplate.tooltip.pointerOrientation = 'left';
        columnTemplate.tooltip.getFillFromObject = false;
        columnTemplate.tooltip.label.fill = am4core.color("#7A7A7A")

        columnTemplate.tooltipX = am4core.percent(50);
        columnTemplate.tooltipY = am4core.percent(0);

        columnTemplate.tooltipText = "{tooltip}{startDate.formatDate('dd.MM.YYYY')}\nКол-во: {value}"
        columnTemplate.fillOpacity = 1;
        columnTemplate.strokeWidth = 0;
        columnTemplate.strokeOpacity = 1;
        columnTemplate.fill = am4core.color("#0FAFE2");

        let fillModifier = new am4core.LinearGradientModifier();
        fillModifier.opacities = [1, 0.5];
        fillModifier.offsets = [0, 1];
        fillModifier.gradient.rotation = 90;
        columnTemplate.fillModifier = fillModifier;

        x.data = data;
        chart.current = x;
    }

    const destroyChart = () => {
        x && x.dispose();
    }

    useEffect(() => {
        const res = parseDynamics(data);
        initChart(res.data);
        return () => destroyChart();
    }, [data]);

    return (
        <>
            <div className={cn('chart')}>
                <div className={cn('chart__header')}>
                    <div className={cn('header__title_col', 'header__title')}>
                        <div className={cn('position__top')}>
                            <h3>{source?.name}</h3>
                        </div>
                        <span className={cn('sub_text', 'position__bottom')}>Период: {PERIOD_LIST[period]}</span>
                    </div>
                </div>
                <div id='chart-source' className={cn('chart__body')} style={{ width: '100%', height: '100%' }} />
            </div>
        </>
    );
};

export {
    ChartTraffic,
    MobileChartTraffic,
};

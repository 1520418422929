import React, { useEffect, useState } from "react";
import cn from "classnames";
import { inject, observer } from "mobx-react";
import { scroller } from "react-scroll";
import { Paginator } from "primereact/paginator";
import Sortable from "../Sortable";
import Download from "./common/Download";
import Filter from "../filter/Filter";
import Charts from "../charts/Charts";
import ReviewItem from "./ReviewItem";
import './ReviewList.scss';
import _ from "underscore";
import { useIsMount } from "../../../../hooks/index";
import PagerSizeSelector from "components/pages/review/list/common/PagerSizeSelector";

const ReviewList = ({ uiStore, tagStore, userStore, reviewsStore, reviewFilterStore }) => {
    /** @type {Array<Branch>} items */
    const { isLoading } = reviewsStore;
    const { filter, setPager } = reviewFilterStore;
    const [changePage, setChangePage] = useState(1);
    const [pagerFirst, setPagerFirst] = useState(0);
    const [pagerRows, setPagerRows] = useState(10);
    const [items, setItems] = useState([])
    const isMount = useIsMount();

    const onBasicPageChange = (event) => {
        setPagerFirst(event.first);
        setPagerRows(event.rows);
        setChangePage((event.page + 1));
        !isMount && scroller.scrollTo('js-reviews-list', {
            duration: 0,
        });
    }

    useEffect(() => {
        if (isMount) {
            tagStore.getTagList();
            tagStore.getAutoTagList();
        }

        isMount && scroller.scrollTo('page-wrapper', {
            duration: 0,
        });
    }, []);

    useEffect(() => {
        if (!isMount) {
            setPager({
                number: changePage,
            })

            const newFilter = reviewFilterStore.parseFilterToApi();
            reviewsStore.loadReviews(newFilter)
                .then(result => {
                    setItems(result.items)
                })
                .catch(e => {
                    console.error(e)
                })

        }
    }, [changePage]);

    useEffect(() => {
        setPagerFirst(0);
        setPager({
            number: 1,
        })

        const newFilter = reviewFilterStore.parseFilterToApi();
        reviewsStore.loadReviews(newFilter)
            .then(result => {
                setItems(result.items)
            })
            .catch(e => {
                console.error(e)
            })
    }, [
        JSON.stringify(filter.date),
        JSON.stringify(filter.tags),
        JSON.stringify(filter.autotags),
        JSON.stringify(filter.branches),
        JSON.stringify(filter.sources),
        JSON.stringify(filter.scores),
        JSON.stringify(filter.sentiments),
        JSON.stringify(filter.claim),
        JSON.stringify(filter.publication),
        JSON.stringify(filter.attention),
        JSON.stringify(filter.answer),
        JSON.stringify(filter.sorting),
        filter.pager.size,
    ]);

    const setCountPagerSize = (count) => {
        setPager({
            size: count,
            number: 1,
        });
    }

    return (
        <>
            <div className={cn('page__review-list', 'page')}>
                <div className={cn('title')}>
                    <h1>Мониторинг</h1>
                    <Download
                        filter={filter}
                        isMobile={false}
                        uiStore={uiStore}
                        userStore={userStore}
                        dest={'review'}
                    />

                </div>

                <div className={cn('review-filter')}>
                    <Filter
                        loading={isLoading}
                    />
                </div>

                <div className={cn('review-charts')}>
                    <Charts/>
                </div>

                <div className={cn('review-list')} id="js-reviews-list">
                    <div className={cn('review-list__table')}>
                        {isLoading && <div className={'loader'}/>}
                        <div className={cn('review-list__header')}>
                            <div className={cn('review-list__header-left')}>
                                <h3>Отзывы</h3>
                                <span className={cn('count')}>{filter.pager.itemCount}</span>
                            </div>
                            <div className={cn('review-list__header-right')}>
                                <div className={cn('sort__list')}>
                                    <div
                                        className={cn('sort__item', filter.sorting.column === 'posted' ? 'active' : '')}>
                                        <Sortable
                                            name="Дата"
                                            sort={filter.sorting}
                                            column='posted'
                                        >
                                            Дата
                                        </Sortable>
                                    </div>

                                    <div
                                        className={cn('sort__item', filter.sorting.column === 'score' ? 'active' : '')}>
                                        <Sortable
                                            name="Оценка"
                                            sort={filter.sorting}
                                            column='score'
                                        >
                                            Оценка
                                        </Sortable>
                                    </div>

                                    <div
                                        className={cn('sort__item', filter.sorting.column === 'source' ? 'active' : '')}>
                                        <Sortable
                                            name="Источник"
                                            sort={filter.sorting}
                                            column='source'
                                        >
                                            Источник
                                        </Sortable>
                                    </div>
                                </div>
                            </div>
                            <div className={cn('review-list__header-right', 'review-list__pager-size')}>
                                <PagerSizeSelector
                                    setCount={setCountPagerSize}
                                    value={filter.pager.size}
                                    options={[
                                        10, 30, 50, 100
                                    ]}
                                />
                            </div>
                        </div>
                        <div className={cn('review-list__wrapper')}>
                            {items.length ? items.map((item, key) => (
                                <ReviewItem
                                    data={item}
                                    key={key + '_' + item.id}
                                />
                            )) : 'нет отзывов'}
                        </div>
                    </div>
                </div>

                <div className={cn('paginator-container')}>
                    {filter.pager.itemCount > 0 && <Paginator
                        first={pagerFirst}
                        rows={filter.pager.size}
                        totalRecords={filter.pager.itemCount}
                        onPageChange={onBasicPageChange}
                    />}
                </div>

            </div>
        </>
    )
}

export default inject('uiStore', 'tagStore', 'userStore', 'reviewsStore', 'reviewFilterStore')(observer(ReviewList));

import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";
import { NavLink, useLocation } from "react-router-dom";
import { inject, observer } from "mobx-react";
import './OkMenu.scss';

const MenuItem = ({ menuItem }) => {
    return (
        <>
            <li className={cn('OkMenu__item')}>
                {!!menuItem.children?.length ?
                    <SubMenu menuItem={menuItem}/>
                    :
                    <NavLink
                        to={menuItem.link}
                        activeClassName='selected'
                    >{menuItem.label}</NavLink>
                }
            </li>
        </>
    )
}

const SubMenu = inject('userStore')(observer(({ userStore, menuItem }) => {
    const subMenuRef = useRef(null);
    const [show, setShow] = useState(false)

    const { profile } = userStore

    useEffect(() => {
        document.addEventListener('click', clickOutsideHandler);
        return () => {
            document.removeEventListener('click', clickOutsideHandler);
        }
    }, [])

    const clickOutsideHandler = (event) => {
        if (!subMenuRef.current?.contains(event.target)) {
            setShow(false)
        }
    }

    const handlerClick = () => {
        setShow(true)
    }

    const handlerCloseSubMenu = () => {
        setShow(false)
    }

    return (
        <>
            <span
                className={cn('submenu__wrapper', { 'selected': menuItem.selected })}
                onClick={handlerClick}
                ref={subMenuRef}
            >
                {menuItem.label}
                <ul className={cn('submenu', { 'active': show })}>
                    {menuItem.children.map((sub, key) => {
                        if (sub.access === 'allow' || profile.allowed[sub.access] === true) {
                            return <SubMenuItem key={key} subMenuItem={sub} onClickSubMenu={handlerCloseSubMenu}/>
                        }
                    })}
                </ul>
            </span>

        </>
    )
}))

const SubMenuItem = ({ subMenuItem, onClickSubMenu }) => {

    return (
        <>
            <li className={cn('submenu__item')}>
                <NavLink to={subMenuItem.link}
                         onClick={(e) => {
                             e.stopPropagation()
                             onClickSubMenu()
                         }}
                         activeClassName='selected'
                >{subMenuItem.label}</NavLink>
            </li>
        </>
    )
}

const OkMenu = ({ menuModel, userStore }) => {
    const location = useLocation();

    const { profile } = userStore;

    const [menu, setMenu] = useState(menuModel);

    useEffect(() => {
        let copy = [...menu];
        Object.keys(copy).map(item => {
            if (copy[item].link === location.pathname) {
                copy[item].selected = true
            }
        })
        setMenu(copy)
    }, [location])

    return (
        <>
            <ul className={cn('OkMenu')}>
                {menu.map((item, key) => {
                    if (item.access === 'allow' || profile.allowed[item.access] === true) {
                        return <MenuItem key={key} menuItem={item}/>
                    }
                })}
            </ul>
        </>
    )
}

export default inject('userStore')(observer(OkMenu));

import React, { useEffect, useState } from "react";
import cn from "classnames";
import './Dashboard.scss';

import { inject, observer } from "mobx-react";

import moment from "moment";
import {
    isMobile
} from "react-device-detect";

import SlideNav from "../analytics/SlideNav";
import Rating from '../review/charts/Rating';
import Dynamics from '../review/charts/Dynamics';
import Pie from '../review/charts/Pie';
import Grade from '../review/charts/Grade';
import { MonitoringReviewsAnswer, MonitoringReviewsClaims } from "./MonitoringReviews";
import RatingBlock from "./RatingBlock";
import RequestsBlock from "./RequestsBlock";
import SMBranchBlock from "components/pages/dashboard/SMBranchBlock";
import SMIntegrationBlock from "components/pages/dashboard/SMIntegrationBlock";
import SMMessageBlock from "components/pages/dashboard/SMMessageBlock";
import { ProgressSpinner } from "primereact/progressspinner";
import { Link } from "react-router-dom";
import { reaction } from "mobx";
import DownloadPdf from "./DownloadPdf";

const Dashboard = ({ dashboardStore, userStore }) => {
    const { filter, sources, resetFilter, setPeriod } = dashboardStore;
    const { profile } = userStore
    const [ratingData, setRatingData] = useState(null);
    const [requestData, setRequestData] = useState(null);
    const [reviewsData, setReviewsData] = useState(null);
    const [reputationData, setReputationData] = useState(null);
    const [integrationData, setIntegrationData] = useState(null);
    const [loading, setLoading] = useState(true);


    const datePeriod = {
        'week': {
            'from': moment().subtract(7, 'day').toString(),
            'to': moment().toString()
        },
        'month': {
            'from': moment().subtract(1, 'month').toString(),
            'to': moment().toString()
        },
    }

    const init = () => {
        setLoading(true);

        Promise.all([
            dashboardStore.getIntegration(),
            dashboardStore.getReputation(),
            dashboardStore.getRequest(),
            dashboardStore.getRating(),
            dashboardStore.getReviewsList(),
            dashboardStore.getSources()
        ])
            .then((
                [
                    integrationResult,
                    reputationResult,
                    requestResult,
                    ratingResult,
                    reviewsListResult,
                ]
            ) => {
                setIntegrationData(integrationResult);
                setReputationData(reputationResult);
                setRequestData(requestResult);
                setRatingData(ratingResult);
                setReviewsData(reviewsListResult);
                setLoading(false);
            })
            .catch(e => {
                console.error('error', e);
            });
    }

    useEffect(init, []);

    useEffect(() => {
        resetFilter();

        reaction(
            () => dashboardStore.filter,
            () => {

                init()
            }
        )
    }, []);

    const parseDateFormat = (date) => {
        return moment(date).format('DD.MM.YY').toString()
    }

    return (
        <>
            <div className={cn('page__dashboard', 'page')}>
                <div className={'dashboard'}>
                    <div className={'title'}>
                        <h1>{profile.client.title}</h1>
                        <h2>Сводка за {parseDateFormat(filter.date.from)} — {parseDateFormat(filter.date.to)}</h2>
                        <div className={cn('dashboard-filter')}>
                            <div className={cn('filter__wrapper')}>
                                <div className={cn('filter__item')}>
                                    <SlideNav
                                        handleChangeNav={(date)=>{
                                            setPeriod(datePeriod[date].from, datePeriod[date].to)
                                        }}
                                        data={[
                                            { 'week': 'Неделя' },
                                            { 'month': 'Месяц' },
                                        ]}
                                        value={'month'}
                                    />
                                    <div className={cn('download__wrapper')}>
                                        <DownloadPdf isMobile={false}/>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'content'}>
                        {!isMobile ? <div className={cn('content__block', 'statuses-messages')}>
                            <h2>Статусы и сообщения →</h2>
                            {profile.allowed.analytics && <div className={cn('row', 'row__md_0')}>
                                {!loading ?
                                    <>
                                        <div className={cn('col', 'col__w50', 'col__w50_min-width', 'branch')}>
                                            {reputationData.total && <SMBranchBlock data={reputationData}/>}
                                        </div>
                                        <div className={cn('col', 'col__w50', 'col__w50_min-width', 'integration')}>
                                            <SMIntegrationBlock data={integrationData}/>
                                        </div>
                                        {/*<SMMessageBlock/>*/}
                                    </>
                                : <div className={'loading'}><ProgressSpinner /></div>
                                }
                            </div>}
                        </div>
                        :
                            <div className={cn('content__block', 'statuses-messages')}>
                                <h2>Статусы и сообщения →</h2>
                                {profile.allowed.analytics && <>
                                    <div className={cn('row', 'row__md_5')}>
                                        {!loading ?
                                            <SMMessageBlock/> : <div className={'loading'}><ProgressSpinner /></div>}
                                    </div>
                                    <div className={cn('row', 'row__md_0')}>
                                        {!loading ?
                                            <>
                                                <div className={cn('col', 'col__w50', 'branch')}>
                                                    <SMBranchBlock data={reputationData}/>
                                                </div>
                                                <div className={cn('col', 'col__w50', 'integration')}>
                                                    <SMIntegrationBlock data={integrationData}/>
                                                </div>
                                            </>
                                            : <div className={'loading'}><ProgressSpinner /></div>
                                        }
                                    </div>
                                </>}
                            </div>
                        }

                        <div className={cn('content__block', 'monitoring')}>
                            <h2>Мониторинг →</h2>
                            {profile.allowed.analytics && <>
                            <div className={cn('row', 'row__md_5')}>
                                    <div className={cn('monitoring__charts')}>
                                        <div className={cn('col', 'col__w25', 'col__w25_min-width')}>
                                            <Link to={'/monitoring/list'}>
                                                <Rating filter={
                                                    {
                                                        date: {
                                                            'from': moment(new Date(filter.date.from)).format("YYYY-MM-DD").toString(),
                                                            'to': moment(new Date(filter.date.to)).format("YYYY-MM-DD").toString(),
                                                        },
                                                    }
                                                }
                                                />
                                            </Link>
                                        </div>
                                        <div className={cn('col', 'col__w25', 'col__w25_min-width')}>
                                            <Link to={'/monitoring/list'}>
                                                <Dynamics filter={
                                                    {
                                                        date: {
                                                            'from': moment(new Date(filter.date.from)).format("YYYY-MM-DD").toString(),
                                                            'to': moment(new Date(filter.date.to)).format("YYYY-MM-DD").toString(),
                                                        },
                                                    }
                                                }
                                                />
                                            </Link>
                                        </div>
                                        <div className={cn('col', 'col__w25', 'col__w25_min-width')}>
                                            <Link to={'/monitoring/list'}>
                                                <Pie filter={
                                                    {
                                                        date: {
                                                            'from': moment(new Date(filter.date.from)).format("YYYY-MM-DD").toString(),
                                                            'to': moment(new Date(filter.date.to)).format("YYYY-MM-DD").toString(),
                                                        },
                                                    }
                                                }
                                                />
                                            </Link>
                                        </div>
                                        <div className={cn('col', 'col__w25', 'col__w25_min-width')}>
                                            <Link to={'/monitoring/list'}>
                                                <Grade filter={
                                                    {
                                                        date: {
                                                            'from': moment(new Date(filter.date.from)).format("YYYY-MM-DD").toString(),
                                                            'to': moment(new Date(filter.date.to)).format("YYYY-MM-DD").toString(),
                                                        },
                                                    }
                                                }
                                                />
                                            </Link>
                                        </div>
                                    </div>
                            </div>
                            {!isMobile ?
                                <div className={cn('row', 'row__md_10')}>
                                    {!loading &&
                                        <>
                                            <div className={cn('col', 'col__w50', 'col__w50_min-width')}>
                                                {!!reviewsData.items?.length && <MonitoringReviewsAnswer data={reviewsData} sources={sources} />}
                                            </div>
                                            <div className={cn('col', 'col__w50', 'col__w50_min-width')}>
                                                {!!reviewsData.items?.length && <MonitoringReviewsClaims data={reviewsData} sources={sources} />}
                                            </div>
                                        </>
                                    }
                                </div>
                                :
                                <>
                                    <div className={cn('row', 'row__md_5')}>
                                        {!loading &&
                                            <div className={cn('col')}>
                                                {!!reviewsData.items?.length && <MonitoringReviewsAnswer data={reviewsData} sources={sources} />}
                                            </div>
                                        }
                                    </div>
                                    <div className={cn('row', 'row__md_0')}>
                                        {!loading &&
                                            <div className={cn('col')}>
                                                {!!reviewsData.items?.length && <MonitoringReviewsClaims data={reviewsData} sources={sources} />}
                                            </div>
                                        }
                                    </div>
                                </>
                            }
                            </>}
                        </div>

                        <div className={cn('content__block')}>
                            <h2>Работа с заявками →</h2>
                            {profile.allowed.analytics && <>
                                {!loading ?
                                    <>
                                    {requestData.close && <RequestsBlock data={requestData}/>}
                                    {ratingData.close && <RatingBlock data={ratingData}/>}
                                    </>
                                    : <div className={'loading'}><ProgressSpinner /></div>
                                }
                            </>}
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}

export default inject('dashboardStore', 'userStore')(observer(Dashboard));
